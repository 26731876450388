import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from './ngrx/app-state/app-state';
import { HttpService } from './services/http-service';

export interface FlexPeriod {
	id: number;
	name: string;
	schedules?: FlexSchedule[];
	deleted_at?: string;
	created_at?: string;
}

export interface FlexSchedule {
	days_of_week: number[];
	start_hour: number;
	start_minute: number;
	end_hour: number;
	end_minute: number;
}

export interface AddFlexScheduleRequest extends FlexSchedule {
	flex_period?: number;
	name?: string;
	schedules: FlexSchedule[];
}

@Injectable({
	providedIn: 'root',
})
export class FlexPeriodService {
	constructor(private http: HttpService, private store: Store<AppState>) {}

	AddScheduleToFlexPeriod(body: AddFlexScheduleRequest) {
		return this.http.post<FlexPeriod>('v2/flex_period/schedules/add', body, undefined, false);
	}

	EditFlexPeriod(body: Partial<FlexPeriod>) {
		return this.http.post<FlexPeriod>('v2/flex_period/update', body, undefined, false);
	}

	RemoveSchedule(id: number) {
		return this.http.post<FlexPeriod>('v2/flex_period/schedules/remove', { id: id }, undefined, false);
	}

	DeleteFlexPeriod(id: number) {
		return this.http.post<object>('v2/flex_period/remove', { id: id }, undefined, false);
	}

	GetAllFlexPeriods() {
		return this.http.post<FlexPeriod[]>('v2/flex_period/list', {}, undefined, false);
	}

	GetFlexPeriodById(id: number) {
		return this.http.post<FlexPeriod[]>('v2/flex_period/list', { id: id }, undefined, false);
	}
}
