<div class="settings-wrapper">
	<div *ngIf="page === 1" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-profile-info [schools]="schools$ | async" [user]="user$ | async" (nextPage)="nextStep()"></app-profile-info>
	</div>
	<div *ngIf="page === 2 && target !== 'display_name'" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-change-password (cancel)="dialogRef.close()" (back)="back()" [user]="user$ | async"> </app-change-password>
	</div>
	<div *ngIf="page === 2 && target === 'display_name'" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-change-displayname (updateInfo)="dialogRef.close($event)" (cancel)="dialogRef.close()" (back)="back()" [user]="user$ | async">
		</app-change-displayname>
	</div>
</div>
