<div class="pass-limit-input-template ds-flex-row" #passLimitInputWrapper>
	<div
		class="tw-w-full tw-flex tw-items-center tw-justify-between tw-text-navy-500 tw-font-normal tw-text-base tw-p-3 tw-rounded-lg tw-border tw-border-gray-150 tw-border-solid tw-box-border"
		[ngClass]="{ 'tw-cursor-pointer': true, 'tw-bg-gray-050': isIndividual, 'tw-bg-white': !isIndividual }"
		controlName="frequency"
		(click)="openPassLimitDropdown()">
		<div *ngIf="control.value; else emtyState">{{ control.value }} {{ control.value == 1 ? 'pass' : 'passes' }}/{{ frequency }}</div>
		<ng-template #emtyState>
			<span class="tw-text-gray-500">Select # passes/{{ frequency }}</span>
		</ng-template>
		<img class="tw-h-2" src="../../../../assets/Chevron%20Down%20(Blue-Gray).svg" />
	</div>
</div>

<ng-template #passLimitDropdown>
	<div class="action-sheet-dialog">
		<div class="options">
			<div
				class="ds-flex-center-between option"
				[ngClass]="{ selected: control.value === limit.value }"
				*ngFor="let limit of filteredArray$ | async"
				(click)="selectValue(limit.value)">
				<span>{{ limit.text }}</span>
				<!--
          app-input is text by default, but The value from the @Input control is a string. We use the double-equal instead of
          the triple equal to avoid more type switching and parsing logic.
        -->
				<img *ngIf="control.value == limit.value" [src]="'./assets/Check (Navy).svg' | resolveAsset" alt="" style="width: 16px; height: 16px" />
			</div>
		</div>
	</div>
</ng-template>
