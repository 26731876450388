<div class="wrapper">
	<div
		class="target-footer"
		[attr.data-motion-opacity]="(frameMotion$ | async).direction"
		[style.height]="date ? '65px' : '43px'"
		[ngClass]="{ 'target-shadow': state !== 'to' }">
		<div class="footer-from-text">
			<div class="date cursor" [style.color]="'#1F195E'" *ngIf="date" (click)="goToDate()" notranslate>{{ date }}</div>
			<div class="center-text">
				<div
					class="footer-left-text"
					[ngClass]="{ cursor: fromCursor }"
					(click)="goToFromWhere()"
					[style.font-weight]="!fromLocation ? 'bold' : 'normal'">
					{{ fromLocationText$ | async }}
				</div>
				<img class="footer-icon" [src]="'./assets/Arrow (Navy).svg' | resolveAsset" />
				<div
					class="footer-right-text"
					[ngClass]="{ cursor: toCursor }"
					(click)="goToToWhere()"
					[style.font-weight]="(!toLocation && state !== 'from') || state === 'to' || state === 'category' ? 'bold' : 'normal'">
					{{ toLocationText }}
				</div>
			</div>
		</div>
	</div>
</div>
