<div
	class="settings-wrapper"
	(scroll)="$event.stopPropagation()"
	*ngIf="data || dataSideNav"
	[style.background-color]="darkTheme.getBackground('high', true)">
	<div class="title" [ngStyle]="{ color: darkTheme.isEnabled$.value ? '#FFFFFF' : '#1F195E' }" *ngIf="!isKioskMode">
		<span *ngIf="user && user.display_name">{{ user.display_name }}</span>
		<span *ngIf="user && !user.display_name">{{ user.first_name }} {{ user.last_name }}</span>
		<span *ngIf="!user">Settings</span>
	</div>

	<div class="user-info" *ngIf="isMobile">
		<div class="user-name" notranslate>{{ user ? user.display_name : '' }}</div>
		<img *ngIf="user.badge" style="margin-left: 15px" width="12" height="17" [src]="'./assets/Referal Badge (Yellow).svg' | resolveAsset" />
	</div>

	<div class="settings-content">
		<div class="profile-switch" *ngIf="isSwitch">
			<div
				class="option"
				#switch
				(mouseenter)="hoveredMasterOption = true"
				(mouseleave)="hoveredMasterOption = false"
				[style.background]="hoveredMasterOption ? (switch.pressed ? 'rgba(127, 135, 157, .15)' : 'rgba(127, 135, 157, .1)') : 'transparent'"
				appCrossPointerEventTarget
				(pointerClickEvent)="hoveredMasterOption = false; switchAction()"
				(pointerDownEvent)="switch.pressed = true; hoveredMasterOption = true"
				(pointerCancelEvent)="switch.pressed = false; hoveredMasterOption = false">
				<bp-icon size="20" icon="change-user" [style.color]="getColor('#FFFFFF', '#7f879d')"></bp-icon>
				<div class="option-title" [style.color]="getColor('#FFFFFF', '#7f879d')">Admin View</div>
				<img *ngIf="!combinedTeacherAdminEnabled" class="arrow-icon" [src]="getIcon('Chevron Right', null) | resolveAsset" />

				<sp-tag *ngIf="combinedTeacherAdminEnabled" [type]="'pill'" [style]="customNuxTagStyle" class="moved-icon">MOVED </sp-tag>
			</div>

			<!--      <mat-divider class="divider"></mat-divider>-->
		</div>
		<div class="setting" *ngFor="let setting of settings; let i = index">
			<div *ngIf="!setting.hidden">
				<div
					class="content"
					#set
					appCrossPointerEventTarget
					(pointerClickEvent)="handleAction(setting); set.hovered = false"
					(pointerDownEvent)="set.pressed = true"
					(pointerCancelEvent)="set.pressed = false; set.hovered = false"
					(mouseenter)="set.hovered = true"
					(mouseleave)="set.hovered = false; set.pressed = false"
					[style.background]="set.pressed ? 'rgba(127, 135, 157, .15)' : set.hovered ? 'rgba(127, 135, 157, .1)' : 'transparent'">
					<bp-icon size="20" [icon]="setting.icon" [style.color]="getColor('#FFFFFF', '#7f879d')" [style.margin-left.px]="20"></bp-icon>
					<div class="setting-title" [style.color]="getColor('#FFFFFF', '#7f879d')">
						{{ setting.title }}
					</div>
					<div
						class="release"
						[class.release-language]="setting.action === 'language'"
						[class.release-swag]="setting.action === 'swagShop'"
						*ngIf="setting.isNew">
						NEW
					</div>
					<div *ngIf="setting.tag" [class.refer-school-tag]="setting.action === 'referSchool'">
						{{ setting.tag }}
					</div>
					<div class="set-up" *ngIf="!(teacherPin$ | async) && setting.action === 'myPin'">Set-Up</div>
				</div>
			</div>
		</div>
	</div>

	<div class="footer">
		<div style="padding: 0 10px">
			<div
				class="sign-out"
				#signOut
				[style.background-color]="hoveredSignout ? (signOut.pressed ? 'rgba(127, 135, 157, .15)' : 'rgba(127, 135, 157, .1)') : 'transparent'"
				[style.color]="getColor('#FFFFFF', '#7f879d')"
				(mouseenter)="hoveredSignout = true"
				(mouseleave)="hoveredSignout = false"
				appCrossPointerEventTarget
				(pointerClickEvent)="hoveredSignout = false; signOutAction()"
				(pointerDownEvent)="signOut.pressed = true; hoveredSignout = true"
				(pointerCancelEvent)="signOut.pressed = false; hoveredSignout = false">
				{{ (kioskMode.getCurrentRoom() | async) ? 'Exit Kiosk Mode & Sign out' : 'Sign out' }}
			</div>
		</div>
		<settings-footer [color]="getColor(null, null)" (openPrivacy)="openLink(settingAction.Privacy)" (openTerms)="openLink(settingAction.Term)">
		</settings-footer>
	</div>
</div>
