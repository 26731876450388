import { Injectable } from '@angular/core';

import { cloneDeep, groupBy, isEqual } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, forkJoin, iif, interval, Observable, of, Subject } from 'rxjs';
import { count, distinctUntilChanged, filter, map, scan, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Util } from '../../Util';
import { LiveDataService } from '../live-data/live-data.service';
import { User } from '../models';
import {
	AgendaForDatesReq,
	Day,
	DeleteBellScheduleTermReq,
	FinalizeScheduleOnboardingReq,
	InsertBellScheduleTermReq,
	InsertBellScheduleTermResp,
	ListScheduleGroupsReq,
	ListScheduleGroupsResp,
	ListSchedulesResp,
	MissedClassTimes,
	MissedClassTimesReq,
	MissedClassTimesResponse,
	Schedule,
	ScheduleForDatesReq,
	ScheduleForDatesResponse,
	ScheduleGroupBellSchedule,
	ScheduleGrouping,
	ScheduleGroupList,
	Term,
	TermData,
	UpdateBellScheduleForDateReq,
	UpdateBellScheduleTermReq,
	UpdateBellScheduleTermResp,
	UpdateBellScheduleTermsReq,
	UpdateBellScheduleTermsResp,
} from '../models/Schedule';
import { School } from '../models/School';
import { ModalState } from '../shorten-or-delete-terms/shorten-or-delete-terms.component';
import { classFromJson, SPClassWithUsers } from './classes.service';
import { FeatureFlagService } from './feature-flag.service';
import { HallPassesService } from './hall-passes.service';
import { HttpService } from './http-service';
import { LocationsService } from './locations.service';
import { OnboardingScheduleService } from './onboarding-schedule.service';
import { SchoolActivity } from './school-activity.service';
import { TimeService } from './time.service';
import { UserService } from './user.service';

export type AgendaResponse = {
	days: {
		[date: string]: {
			activity_agendas: ActivityAgendaItem[];
			class_agendas: ClassAgendaItem[];
			period_agendas: PeriodAgendaItem[];
			date: string;
			no_school_day: string | null;
		};
	};
};

export type ClassAgendaItem = {
	start_time: string;
	end_time: string;
	date: string;
	schedule_group_id: number;
	class: SPClassWithUsers;
};

export type ActivityAgendaItem = {
	start_time: string;
	end_time: string;
	date: string;
	instance_id: number;
	activity: SchoolActivity;
	students: User[];
};

export type PeriodAgendaItem = {
	start_time: string;
	end_time: string;
	long_name: string;
	schedule_group_id: number;
	room_id: number | null;
};

export type CountdownData = {
	noSchedule: boolean;
	beforeSchool: boolean;
	schoolOver: boolean;
	noActivePeriod: boolean;
	currentPeriod: PeriodAgendaItem;
	nextPeriod: PeriodAgendaItem;
	timeUntilNextPeriodStarts: moment.Duration;
	timeUntilCurrentPeriodEnds: moment.Duration;
	isRotating: boolean;
	multipleDayTypes: boolean;
	hasPeriodTimes: boolean;
	isWeekend: boolean;
};

export type ScheduleGroupClassAgendas = {
	start_time: string;
	end_time: string;
	schedule_group_id: number;
	schedule_group_index: number;
	class_agendas: ClassAgendaItem[];
};
export type ScheduleGroupIndexChange = {
	manualTrigger: boolean;
	index: number;
};

export type DisplayWeek = {
	startDate: moment.Moment;
	endDate: moment.Moment;
	days: DisplayWeekDayInfo[];
};
export type DisplayWeekDayInfo = {
	dayAbbreviation: string;
	date: string;
	dayType: string;
	isHoliday: boolean;
	isToday: boolean;
	isSelected: boolean;
	modified: boolean;
};
export const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;

@Injectable({
	providedIn: 'root',
})
export class ScheduleService {
	private interval$ = interval(1000).pipe(startWith(0));
	dayChange$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
	isWeekend$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	currentSchool: School | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	displayShortenOrDeleteTerms$: BehaviorSubject<ModalState> = new BehaviorSubject<ModalState>('closed');

	//Schedule data for current user
	mySchedulesAgenda$: BehaviorSubject<AgendaResponse> = new BehaviorSubject<AgendaResponse>(null);
	mySchedulesAgendaForToday$ = this.mySchedulesAgenda$.pipe(
		filter((ar) => !!ar),
		map((ar) => {
			const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
			// this can be undefined if there are no schedules for today
			// we can't really filter out the undefined value in subsequent pipes because, we want Observables
			// to complete and continue with some empty-state logic if there are no schedules for the day
			return ar.days[today];
		})
	);
	mySchedules$: BehaviorSubject<ScheduleForDatesResponse> = new BehaviorSubject<ScheduleForDatesResponse>(null);
	scheduleGroupIndex$: BehaviorSubject<ScheduleGroupIndexChange> = new BehaviorSubject<ScheduleGroupIndexChange>({
		manualTrigger: false,
		index: 0,
	});

	// The current schedules for any days that are shown while editing schedules. This includes schedules for dates around the selected date, because we display the whole week at once.
	schedulesForEditing$: BehaviorSubject<ScheduleForDatesResponse> = new BehaviorSubject<ScheduleForDatesResponse>(null);
	// Used only in edit flows, this is the current date we are inspecting and editing bell schedules for.
	selectedBellScheduleEditDate$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(
		Util.ensureDateIsNotWeekend(moment.utc(this.timeService.nowDate()))
	);

	// The schedules for editing a holiday in the past. We retrieve data so the day type picker will have accurate day types to display.
	schedulesForEditingHoliday$: BehaviorSubject<ScheduleForDatesResponse> = new BehaviorSubject<ScheduleForDatesResponse>(null);
	// Used only in edit holidays flows when the holiday is in the past.
	selectedBellScheduleEditForHolidayDate$: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(moment(this.timeService.nowDate()));

	schedulesList$: Observable<Schedule[]> = this.http.currentSchool$.pipe(
		filter((s) => !!s),
		switchMap(() => this.listSchedules()),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	schoolYearsScheduleList$: BehaviorSubject<Schedule[]> = new BehaviorSubject<Schedule[]>([]);

	activeSchedule$: Observable<Schedule> = this.schedulesList$.pipe(
		filter((sl) => !!sl),
		map((sl) => {
			return sl.find((s) => s.status === 'active');
		})
	);

	scheduleListGroups$: Observable<ScheduleGroupList[]> = this.activeSchedule$.pipe(
		filter((as) => !!as),
		switchMap((as) => {
			return this.getScheduleListGroups(as.id);
		})
	);

	selectedSchedule$: Observable<Schedule> = this.schedulesList$.pipe(
		filter((s) => !!s),
		map((schedules) => {
			return schedules[0];
		})
	);

	listGroups$: Observable<ScheduleGroupList[]> = this.selectedSchedule$.pipe(
		filter((s) => !!s),
		switchMap((selected) => {
			return this.getScheduleListGroups(selected.id);
		})
	);

	//Schedule data for another user
	otherSchedulesAgenda$: BehaviorSubject<AgendaResponse> = new BehaviorSubject<AgendaResponse>(null);
	otherSchedulesAgendaForToday$ = this.otherSchedulesAgenda$.pipe(
		filter((ar) => !!ar),
		map((ar) => {
			const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
			return ar.days[today];
		})
	);
	otherScheduleListGroups$: BehaviorSubject<ScheduleGroupList[]> = new BehaviorSubject<ScheduleGroupList[]>(null);
	otherSchedulesList$: BehaviorSubject<Schedule[]> = new BehaviorSubject<Schedule[]>(null);
	otherActiveSchedule$: Observable<Schedule> = this.otherSchedulesList$.pipe(
		filter((sl) => !!sl),
		map((sl) => {
			return sl.find((s) => s.status === 'active');
		})
	);
	otherSelectedSchedule$: BehaviorSubject<Schedule> = new BehaviorSubject<Schedule>(null);
	otherListGroups$ = this.otherSelectedSchedule$.pipe(
		filter((s) => !!s),
		switchMap((selected) => {
			return this.getScheduleListGroups(selected.id);
		})
	);

	myScheduleForToday$: Observable<Day> = this.mySchedules$.pipe(
		filter((s) => !!s),
		map((s) => {
			const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
			return s.days[today];
		})
	);

	scheduleGroupId$ = combineLatest(this.scheduleGroupIndex$, this.myScheduleForToday$).pipe(
		filter(([indexChange, schedule]) => !!indexChange && !!schedule),
		map(([indexChange, schedule]) => {
			return schedule.schedule_groups[indexChange.index]?.id || schedule.schedule_groups[0]?.id;
		})
	);

	scheduleGroupsClassAgendas$: Observable<ScheduleGroupClassAgendas[]> = combineLatest([
		this.mySchedulesAgendaForToday$,
		this.myScheduleForToday$,
	]).pipe(
		filter(([agenda, schedule]) => !!agenda && !!schedule),
		map(([agenda, schedule]) => {
			const groupedAgendaClasses = groupBy(agenda.class_agendas, (c: ClassAgendaItem) => c.schedule_group_id);
			const result: ScheduleGroupClassAgendas[] = [];
			schedule.schedule_groups.forEach((sg, i) => {
				sg.period_bubbles.forEach((pg) => {
					const agendaClasses = groupedAgendaClasses[sg.id]?.filter((ca) => ca.start_time === pg.start_time && ca.end_time === pg.end_time) || [];
					result.push({
						start_time: pg.start_time,
						end_time: pg.end_time,
						schedule_group_index: i,
						schedule_group_id: sg.id,
						class_agendas: agendaClasses,
					});
				});
			});
			return result.sort((a, b) => {
				const [aHours, aMinutes] = a.start_time.split(':').map(Number);
				const [bHours, bMinutes] = b.start_time.split(':').map(Number);

				const aTotalMinutes = aHours * 60 + aMinutes;
				const bTotalMinutes = bHours * 60 + bMinutes;

				return aTotalMinutes - bTotalMinutes;
			});
		})
	);

	periodTimes$: Observable<PeriodAgendaItem[]> = combineLatest(this.mySchedulesAgendaForToday$, this.scheduleGroupId$).pipe(
		takeUntil(this.destroy$),
		map(([s, i]) => {
			if (!s) {
				return [];
			}
			const periods = s.period_agendas;
			const grouped = groupBy(periods, (p: PeriodAgendaItem) => p.schedule_group_id);
			return grouped[i] || [];
		})
	);

	scheduleGroups$: Observable<ScheduleGrouping[]> = this.myScheduleForToday$.pipe(
		map((s) => {
			if (!s) {
				return null;
			}
			if (!s.schedule_groups || s.schedule_groups.length === 0) {
				return null;
			}
			return s.schedule_groups;
		})
	);

	scheduleGroup$: Observable<ScheduleGrouping> = combineLatest(this.myScheduleForToday$, this.scheduleGroupId$).pipe(
		map(([s, i]) => {
			if (!s) {
				return null;
			}
			if (!s.schedule_groups || s.schedule_groups.length === 0) {
				return null;
			}
			return s.schedule_groups.find((sg) => sg.id === i);
		})
	);

	currentPeriod$: Observable<PeriodAgendaItem> = this.periodTimes$.pipe(
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					return this.getActivePeriod(pt);
				})
			);
		})
	);

	nextPeriod$: Observable<PeriodAgendaItem> = this.periodTimes$.pipe(
		filter((pt) => !!pt),
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					return this.getNextPeriod(pt);
				})
			);
		})
	);

	scheduleTooltipHeight$: Observable<number> = this.periodTimes$.pipe(count());

	activityAgendaItems$: Observable<ActivityAgendaItem[]> = this.mySchedulesAgendaForToday$.pipe(
		filter((agenda) => !!agenda),
		switchMap((agenda) => this.interval$.pipe(map(() => this.getActiveActivities(agenda.activity_agendas))))
	);

	currentAgendaItems$: Observable<ClassAgendaItem[]> = combineLatest([this.mySchedulesAgendaForToday$, this.scheduleGroupId$]).pipe(
		filter(([agenda]) => !!agenda),
		map(([agenda, scheduleGroupId]) => agenda?.class_agendas.filter((ca) => ca.schedule_group_id === scheduleGroupId) ?? []),
		switchMap((classAgendas) => this.interval$.pipe(map(() => this.getActiveClasses(classAgendas))))
	);

	nextAgendaItems$: Observable<ClassAgendaItem[]> = combineLatest([this.mySchedulesAgendaForToday$, this.nextPeriod$]).pipe(
		filter(([agenda]) => !!agenda),
		switchMap(([agenda, nextPeriod]) => {
			return this.interval$.pipe(
				map(() => {
					if (nextPeriod && agenda?.class_agendas) {
						const agendaItemsForScheduleGroup = agenda.class_agendas.filter((ca) => ca.schedule_group_id === nextPeriod.schedule_group_id);
						return this.getAgendaItemsInPeriod(agendaItemsForScheduleGroup, nextPeriod);
					} else {
						return [];
					}
				})
			);
		})
	);

	hasPeriodTimes$: Observable<boolean> = this.periodTimes$.pipe(
		map((pt) => {
			if (pt === null) {
				return false;
			}
			return pt.length > 0;
		})
	);

	schoolOver$: Observable<boolean> = this.periodTimes$.pipe(
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					if (pt?.length == 0) {
						return false;
					}
					return Util.getTimeUntil(pt[pt.length - 1].end_time).asMilliseconds() < 0;
				})
			);
		})
	);

	beforeSchool$: Observable<boolean> = this.periodTimes$.pipe(
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					if (pt?.length == 0) {
						return false;
					}
					return Util.getTimeUntil(pt[0].start_time).asMilliseconds() > THIRTY_MINUTES_IN_MILLISECONDS;
				})
			);
		})
	);

	timeUntilNextPeriodStarts$: Observable<moment.Duration> = this.nextPeriod$.pipe(
		filter((np) => !!np),
		switchMap((np) => {
			const timeUntil = Util.getTimeUntil(np.start_time);
			return iif(() => timeUntil.asMilliseconds() > 0, this.interval$.pipe(map(() => timeUntil)), of(moment.duration(0)));
		})
	);

	timeUntilCurrentPeriodEnds$: Observable<moment.Duration> = this.currentPeriod$.pipe(
		filter((cp) => !!cp),
		switchMap((cp) => {
			const timeUntil = Util.getTimeUntil(cp.end_time);
			return iif(() => timeUntil.asMilliseconds() > 0, this.interval$.pipe(map(() => timeUntil)), of(moment.duration(0)));
		})
	);

	noClassThisPeriod$: Observable<boolean> = this.currentAgendaItems$.pipe(
		map((agendas) => {
			return agendas?.length == 0;
		})
	);

	noActivePeriod$: Observable<boolean> = this.currentPeriod$.pipe(
		map((pt) => {
			return pt == null;
		})
	);

	otherPeriodTimes$: Observable<PeriodAgendaItem[]> = this.otherSchedulesAgendaForToday$.pipe(
		takeUntil(this.destroy$),
		filter((s) => !!s),
		map((s) => {
			return s?.period_agendas || [];
		})
	);

	otherCurrentPeriod$: Observable<PeriodAgendaItem> = this.otherPeriodTimes$.pipe(
		filter((pt) => !!pt),
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					return this.getActivePeriod(pt);
				})
			);
		})
	);

	otherNextPeriod$: Observable<PeriodAgendaItem> = this.otherPeriodTimes$.pipe(
		filter((pt) => !!pt),
		switchMap((pt) => {
			return this.interval$.pipe(
				map(() => {
					return this.getNextPeriod(pt);
				})
			);
		})
	);

	otherCurrentAgendaItems$: Observable<ClassAgendaItem[]> = combineLatest([this.otherSchedulesAgendaForToday$, this.scheduleGroupId$]).pipe(
		filter(([agenda, _]) => !!agenda),
		map(([agenda, scheduleGroupId]) => agenda?.class_agendas.filter((ca) => ca.schedule_group_id === scheduleGroupId) ?? []),
		switchMap((classAgendas) => this.interval$.pipe(map(() => this.getActiveClasses(classAgendas))))
	);

	otherNextAgendaItems$: Observable<ClassAgendaItem[]> = combineLatest([this.otherSchedulesAgendaForToday$, this.otherNextPeriod$]).pipe(
		filter(([agenda]) => !!agenda),
		switchMap(([agenda, nextPeriod]) => {
			return this.interval$.pipe(
				map(() => {
					if (nextPeriod && agenda.class_agendas) {
						return this.getAgendaItemsInPeriod(agenda.class_agendas, nextPeriod);
					} else {
						return [];
					}
				})
			);
		})
	);

	otherTimeUntilNextPeriodStarts$: Observable<moment.Duration> = this.otherNextPeriod$.pipe(
		filter((np) => !!np),
		switchMap((np) => {
			const timeUntil = Util.getTimeUntil(np.start_time);
			return iif(() => timeUntil.asMilliseconds() > 0, this.interval$.pipe(map(() => timeUntil)), of(moment.duration(0)));
		})
	);

	otherTimeUntilCurrentPeriodEnds$: Observable<moment.Duration> = this.otherCurrentPeriod$.pipe(
		filter((cp) => !!cp),
		switchMap((cp) => {
			const timeUntil = Util.getTimeUntil(cp.end_time);
			return iif(() => timeUntil.asMilliseconds() > 0, this.interval$.pipe(map(() => timeUntil)), of(moment.duration(0)));
		})
	);

	otherNoClassThisPeriod$: Observable<boolean> = this.otherCurrentAgendaItems$.pipe(
		map((agendas) => {
			return agendas?.length == 0;
		})
	);

	otherNoActivePeriod$: Observable<boolean> = this.otherCurrentPeriod$.pipe(
		map((pt) => {
			return pt == null;
		})
	);

	countdownData$: Observable<CountdownData> = combineLatest(
		this.myScheduleForToday$.pipe(map((s) => !s)),
		this.beforeSchool$.pipe(startWith(false)),
		this.schoolOver$.pipe(startWith(false)),
		this.noActivePeriod$.pipe(startWith(false)),
		this.currentPeriod$.pipe(startWith(null)),
		this.nextPeriod$.pipe(startWith(null)),
		this.timeUntilNextPeriodStarts$.pipe(startWith(0)),
		this.timeUntilCurrentPeriodEnds$.pipe(startWith(0)),
		this.listGroups$.pipe(shareReplay(1)),
		this.hasPeriodTimes$.pipe(startWith(false)),
		this.isWeekend$.pipe(startWith(false))
	).pipe(
		map(
			([
				noSchedule,
				beforeSchool,
				schoolOver,
				noActivePeriod,
				currentPeriod,
				nextPeriod,
				timeUntilNextPeriodStarts,
				timeUntilCurrentPeriodEnds,
				groups,
				hasPeriodTimes,
				isWeekend,
			]) => {
				const firstShortName = (groups as ScheduleGroupList[])[0].bell_schedule_groups[0].bell_schedules[0].short_name;
				return {
					noSchedule: noSchedule as boolean,
					beforeSchool: beforeSchool as boolean,
					schoolOver: schoolOver as boolean,
					noActivePeriod: noActivePeriod as boolean,
					currentPeriod: currentPeriod as PeriodAgendaItem,
					nextPeriod: nextPeriod as PeriodAgendaItem,
					timeUntilNextPeriodStarts: timeUntilNextPeriodStarts as moment.Duration,
					timeUntilCurrentPeriodEnds: timeUntilCurrentPeriodEnds as moment.Duration,
					isRotating: (groups as ScheduleGroupList[]).some((g) => g.rotation_type === 'rotating'),
					multipleDayTypes: !(groups as ScheduleGroupList[]).every((group) =>
						group.bell_schedule_groups.every((bsg) => bsg.bell_schedules.every((bs) => bs.short_name === firstShortName))
					),
					hasPeriodTimes: hasPeriodTimes as boolean,
					isWeekend,
				};
			}
		)
	);

	daysForWeek$: Observable<DisplayWeek> = combineLatest([
		this.schedulesForEditing$,
		this.scheduleGroupIndex$,
		this.selectedBellScheduleEditDate$,
	]).pipe(
		filter(([scheduleForDates, selectedScheduleGroupIndex, selectedDate]) => !!scheduleForDates && !!selectedScheduleGroupIndex && !!selectedDate),
		distinctUntilChanged((prev, curr) => {
			const prevWeekOfYear = moment.utc(prev[2]).week();
			const currWeekOfYear = moment.utc(curr[2]).week();
			const weekChanged = prevWeekOfYear !== currWeekOfYear;
			const selectedDateChanged = !moment.utc(prev[2]).isSame(moment.utc(curr[2]));
			const dataChanged = !isEqual(prev[0], curr[0]) || prev[1].index !== curr[1].index;
			// If the week has changed AND any of the other data has changed,
			// OR if data has changed but not week, OR scheduleForDates has empty days, then proceed to the map.
			// (If scheduleForDates has empty days, it means the date is outside the school year, and we still need to provide day info.)
			// If we allow the map to run when the selectedDate has changed but not to a new week,
			// then the UI will load the data and appear to "blink", so we need to prevent that.
			// We also have to make sure the data has also changed to the new week's data.
			return !((weekChanged && dataChanged) || selectedDateChanged || dataChanged || Object.keys(curr[0].days).length === 0);
		}),
		map(([scheduleForDates, selectedScheduleGroupIndex, selectedDate]) => {
			// Map the data into the structure we want to display in the UI:
			// for each weekday in the week of the selected date, get the day type from the schedule data and selected schedule group index
			// and create a DisplayWeekDayInfo object for it.
			const weekdaysForDate = Util.getWeekDaysForDate(selectedDate);
			const daysInfo: DisplayWeekDayInfo[] = weekdaysForDate.map((day) => {
				let dayType = '';
				let isHoliday = false;
				let isModified = false;
				if (scheduleForDates.days[day.format('YYYY-MM-DD')]?.holiday) {
					isHoliday = true;
				}
				if (scheduleForDates.days[day.format('YYYY-MM-DD')]?.schedule_groups[selectedScheduleGroupIndex.index]?.modified) {
					isModified = true;
				}
				if (scheduleForDates.days[day.format('YYYY-MM-DD')]?.schedule_groups.length) {
					dayType = scheduleForDates.days[day.format('YYYY-MM-DD')]?.schedule_groups[selectedScheduleGroupIndex.index].day_name;
					if (dayType == 'Other') {
						dayType = '*';
					}
				}
				return this.getDayInfo(day, dayType, isHoliday, isModified, selectedDate);
			});
			return {
				startDate: weekdaysForDate[0],
				endDate: weekdaysForDate[4],
				days: daysInfo,
			};
		})
	);

	constructor(
		private http: HttpService,
		private featureFlagService: FeatureFlagService,
		public locationService: LocationsService,
		private userService: UserService,
		private liveData: LiveDataService,
		private timeService: TimeService,
		private onboardingScheduleService: OnboardingScheduleService,
		private hallPassesService: HallPassesService
	) {
		// this.startScheduleInterval();
	}

	getAgendaForDates(userId: number, start_date: Date, end_date: Date): Observable<AgendaResponse> {
		const req: AgendaForDatesReq = {
			user_id: userId,
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).format('YYYY-MM-DD'),
		};
		return this.http.post<AgendaResponse>('v2/schedules/GetAgendaForDates', req, undefined, false).pipe(
			switchMap((response) => {
				const classObservables: Observable<SPClassWithUsers>[] = [];
				Object.keys(response.days).forEach((key) => {
					response.days[key].class_agendas.forEach((a) => {
						classObservables.push(classFromJson(a.class, this.hallPassesService));
					});
				});
				// Check if classObservables is empty
				if (classObservables.length === 0) {
					return of(response); // Return response immediately
				}
				return forkJoin(classObservables).pipe(
					map((classes) => {
						let classIndex = 0;
						Object.keys(response.days).forEach((key) => {
							response.days[key].class_agendas.forEach((a) => {
								a.class = classes[classIndex++];
								a.class.collapsed = this.classShouldBeCollapsed(a, response.days[key].class_agendas);
								a.class.class_users.students = a.class.class_users.students.sort((a, b) => {
									return a.user.last_name.localeCompare(b.user.last_name);
								});
							});
						});
						return response;
					})
				);
			})
		);
	}

	getTermData$: Observable<TermData> = this.activeSchedule$.pipe(
		map((schedule) => {
			const terms = schedule?.subterms ? schedule.subterms : schedule?.term ? [schedule?.term] : [];
			if (terms?.length > 0) {
				const { current, upcoming } = this.findCurrentAndUpcomingTerm(terms);
				return {
					currentTerm: current,
					upcomingTerm: upcoming,
					termsOverlap: this.checkForOverlappingTerms(terms),
					termsSetup: terms?.length > 0,
				};
			} else {
				return {
					currentTerm: null,
					upcomingTerm: null,
					termsOverlap: false,
					termsSetup: false,
				};
			}
		})
	);

	findCurrentAndUpcomingTerm(terms: Term[]): { current: Term | null; upcoming: Term | null } {
		if (terms.length === 0) {
			return { current: null, upcoming: null };
		}

		// Ensure terms are sorted by start_date before iterating
		terms.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

		const now = new Date();
		let currentTerm: Term | null = null;
		let upcomingTerm: Term | null = null;

		for (const term of terms) {
			const startDate = new Date(term.start_date);
			const endDate = new Date(term.end_date);

			if (Util.isDateBetween(startDate, now, endDate)) {
				currentTerm = term;
				const nextIndex = terms.indexOf(term) + 1;
				if (nextIndex < terms.length) {
					upcomingTerm = terms[nextIndex];
				}
				break;
			} else if (!currentTerm && startDate > now && !upcomingTerm) {
				upcomingTerm = term;
			}
		}
		return { current: currentTerm, upcoming: upcomingTerm };
	}

	checkForOverlappingTerms(terms: Term[]): boolean {
		for (let i = 0; i < terms.length - 1; i++) {
			for (let j = i + 1; j < terms.length; j++) {
				const termA = terms[i];
				const termB = terms[j];

				const startDateA = new Date(termA.start_date);
				const endDateA = new Date(termA.end_date);
				const startDateB = new Date(termB.start_date);
				const endDateB = new Date(termB.end_date);

				// Check for overlap
				if (startDateA <= endDateB && endDateA >= startDateB) {
					return true; // Overlapping terms found
				}
			}
		}
		return false; // No overlapping terms found
	}

	private classShouldBeCollapsed(item: ClassAgendaItem, agendaItems: ClassAgendaItem[]): boolean {
		// If there is more than one class in the same period in the same room, it should be collapsed.
		// This checks if a particular agenda item occurs in the same period as any others,
		// and also checks if the class in the agenda item are in the same room.
		const startTime = new Date();
		startTime.setHours(parseInt(item.start_time.split(':')[0]));
		startTime.setMinutes(parseInt(item.start_time.split(':')[1]));
		const overlappingItems = agendaItems.filter((i) => {
			if (Util.isTimeBetween(startTime, i.start_time, i.end_time)) {
				return true;
			}
		});
		const itemsInSameRoom = overlappingItems.filter((i) => {
			return i.class.room.id === item.class.room.id;
		});
		return itemsInSameRoom.length > 1;
	}

	getScheduleListGroups(scheduleId: number): Observable<ScheduleGroupList[]> {
		const req: ListScheduleGroupsReq = {
			schedule_id: scheduleId,
		};
		const data = this.http.post<ListScheduleGroupsResp>('v2/schedules/ListScheduleGroups', req, undefined, false).pipe(
			map((response) => {
				return response.schedule_group_list;
			})
		);
		return data;
	}

	listSchedules(): Observable<Schedule[]> {
		const data = this.http.post<ListSchedulesResp>('v2/schedules/ListSchedules', {}, undefined, false).pipe(
			map((response) => {
				this.schoolYearsScheduleList$.next(response.schedules);
				return response.schedules || [];
			})
		);
		return data;
	}

	getScheduleForDates(start_date: Date, end_date: Date): Observable<ScheduleForDatesResponse> {
		const req: ScheduleForDatesReq = {
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).format('YYYY-MM-DD'),
		};
		const data = this.http.post<ScheduleForDatesResponse>('v2/schedules/GetScheduleForDates', req, undefined, false).pipe(
			map((response) => {
				this.mySchedules$.next(response);
				return response;
			})
		);
		return data;
	}

	getScheduleForEditedDates(start_date: moment.Moment, end_date: moment.Moment): Observable<ScheduleForDatesResponse> {
		const mStartDate = Util.ensureDateIsNotWeekend(moment.utc(start_date));
		const mEndDate = Util.ensureDateIsNotWeekend(moment.utc(end_date));
		const req: ScheduleForDatesReq = {
			start_date: mStartDate.format('YYYY-MM-DD'),
			end_date: mEndDate.format('YYYY-MM-DD'),
		};
		const data = this.http.post<ScheduleForDatesResponse>('v2/schedules/GetScheduleForDates', req, undefined, false).pipe(
			map((response) => {
				this.schedulesForEditing$.next(response);
				return response;
			})
		);
		return data;
	}
	getScheduleForEditedHolidayDates(start_date: Date, end_date: Date): Observable<ScheduleForDatesResponse> {
		const req: ScheduleForDatesReq = {
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).format('YYYY-MM-DD'),
		};
		const data = this.http.post<ScheduleForDatesResponse>('v2/schedules/GetScheduleForDates', req, undefined, false).pipe(
			map((response) => {
				this.schedulesForEditingHoliday$.next(response);
				return response;
			})
		);
		return data;
	}
	getScheduleForDeletedHolidayDates(start_date: Date, end_date: Date): Observable<ScheduleForDatesResponse> {
		const req: ScheduleForDatesReq = {
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).format('YYYY-MM-DD'),
		};
		const data = this.http.post<ScheduleForDatesResponse>('v2/schedules/GetScheduleForDates', req, undefined, false).pipe(
			map((response) => {
				return response;
			})
		);
		return data;
	}

	getScheduleForDate(date: Date, data: ScheduleForDatesResponse): Day | null {
		const dateStr = moment.utc(date).format('YYYY-MM-DD');
		if (dateStr && data?.days) {
			return data?.days[dateStr];
		}
		return null;
	}

	getAgendaItemsInPeriod(agendaItems: ClassAgendaItem[], period: PeriodAgendaItem): ClassAgendaItem[] {
		return agendaItems.filter((a) => {
			return (
				(a.start_time >= period.start_time && a.start_time < period.end_time) || (a.end_time > period.start_time && a.end_time < period.end_time)
			);
		});
	}

	getNextAgendaItem(agenda: ClassAgendaItem[]): ClassAgendaItem {
		if (agenda.length <= 0) {
			return;
		}
		const currentTime = new Date();

		const afterSchool = Util.isTimeAfter(currentTime, agenda[agenda.length - 1].end_time);
		if (afterSchool) {
			return;
		}

		const beforeSchool = Util.isTimeBefore(currentTime, agenda[0].start_time);
		if (beforeSchool) {
			return agenda[0];
		}

		// the next agenda will be the one that is after the previous agenda's start but has
		// a start_time before now
		const nextAgenda = agenda.find((pg, index) => {
			if (index !== 0) {
				const previousAgendaStartTime = agenda[index - 1].start_time;
				if (Util.isTimeBetween(currentTime, previousAgendaStartTime, pg.start_time)) {
					return pg;
				}
			}
		});
		return nextAgenda;
	}

	getActivePeriod(periodAgendaItems: PeriodAgendaItem[] | null): PeriodAgendaItem | null {
		const currentTime = new Date();
		return (periodAgendaItems ?? []).find((pg) => Util.isTimeBetween(currentTime, pg.start_time, pg.end_time)) ?? null;
	}

	getActiveClasses(classAgendaItems: ClassAgendaItem[]): ClassAgendaItem[] {
		const currentTime = this.timeService.nowDate();
		return (classAgendaItems ?? []).filter((classAgendaItem) =>
			Util.isTimeBetween(currentTime, classAgendaItem.start_time, classAgendaItem.end_time)
		);
	}

	getActiveActivities(activityAgendaItems: ActivityAgendaItem[]): ActivityAgendaItem[] {
		const currentTime = this.timeService.nowDate();
		return (activityAgendaItems ?? []).filter((activityAgendaItems) => {
			const start = moment.utc(activityAgendaItems.start_time).subtract(5, 'minutes');
			const end = moment.utc(activityAgendaItems.end_time);
			return moment.utc(currentTime).isBetween(start, end);
		});
	}

	//checks period times and returns the next index of it
	getNextPeriod(periodAgendaItem: PeriodAgendaItem[]): PeriodAgendaItem {
		if (periodAgendaItem.length <= 0) {
			return;
		}
		const currentTime = new Date();

		const afterSchool = Util.isTimeAfter(currentTime, periodAgendaItem[periodAgendaItem.length - 1].end_time);
		if (afterSchool) {
			return;
		}

		const beforeSchool = Util.isTimeBefore(currentTime, periodAgendaItem[0].start_time);
		if (beforeSchool) {
			return periodAgendaItem[0];
		}

		// the next period will be the one that is after the previous period's start but has
		// a start_time before now

		const nextPeriod = periodAgendaItem.find((pg, index) => {
			if (index !== 0) {
				const previousPeriodStartTime = periodAgendaItem[index - 1].start_time;
				if (Util.isTimeBetween(currentTime, previousPeriodStartTime, pg.start_time)) {
					return pg;
				}
			}
		});
		return nextPeriod || null;
	}

	updateScheduleForDate(req: UpdateBellScheduleForDateReq): Observable<ScheduleForDatesResponse> {
		const data = this.http.post<ScheduleForDatesResponse>('v2/bell_schedules/UpdateBellScheduleForDate', req, undefined, false).pipe(
			scan((acc, newData) => this.addUpdatedScheduleToStream(acc, newData), this.schedulesForEditing$.getValue()),
			map((response) => {
				this.schedulesForEditing$.next(response);
				return response;
			})
		);
		return data;
	}
	finalizeScheduleOnboarding(req: FinalizeScheduleOnboardingReq): Observable<ScheduleForDatesResponse> {
		const data = this.http.post<ScheduleForDatesResponse>('v2/schedules/FinalizeScheduleOnboarding', req, undefined, false).pipe(
			scan((acc, newData) => this.addUpdatedScheduleToStream(acc, newData), this.schedulesForEditing$.getValue()),
			map((response) => {
				this.schedulesForEditing$.next(response);
				this.onboardingScheduleService.onBoardingStatus$.next('active');
				return response;
			})
		);
		return data;
	}

	private addUpdatedScheduleToStream(currentData: ScheduleForDatesResponse, newData: ScheduleForDatesResponse): ScheduleForDatesResponse {
		// Without cloning the data, the BehaviorSubject won't emit a new value because it's the same reference.
		const updatedData = cloneDeep(currentData);
		return { days: { ...updatedData.days, ...newData.days } };
	}

	private getDayInfo(day: moment.Moment, dayType: string, isHoliday: boolean, isModified: boolean, selectedDate: moment.Moment): DisplayWeekDayInfo {
		return {
			dayAbbreviation: day.format('ddd'),
			date: day.format('D'),
			dayType: dayType,
			isToday: day.isSame(moment(), 'day'),
			isSelected: day.isSame(selectedDate, 'day'),
			isHoliday: isHoliday,
			modified: isModified,
		};
	}

	listenForBellScheduleUpdate() {
		return this.liveData.watchUpdatedBellSchedule();
	}

	addBellScheduleTerms(req: InsertBellScheduleTermReq): Observable<Term[]> {
		return this.http.post<InsertBellScheduleTermResp>('v2/bell_schedules/AddBellScheduleTerms', req, undefined, false).pipe(
			map((response) => {
				return response.terms;
			})
		);
	}
	deleteBellScheduleTermsById(req: DeleteBellScheduleTermReq): Observable<object> {
		return this.http.post<object>('v2/bell_schedules/DeleteBellScheduleTermsById', req, undefined, false);
	}
	updateBellScheduleTerm(req: UpdateBellScheduleTermReq): Observable<Term> {
		return this.http.post<UpdateBellScheduleTermResp>('v2/bell_schedules/UpdateBellScheduleTerm', req, undefined, false).pipe(
			map((response) => {
				return response.term;
			})
		);
	}
	updateBellScheduleTerms(req: UpdateBellScheduleTermsReq): Observable<Term[]> {
		return this.http.post<UpdateBellScheduleTermsResp>('v2/bell_schedules/UpdateBellScheduleTerms', req, undefined, false).pipe(
			map((response) => {
				return response.terms;
			})
		);
	}

	getMissedClassTimeForStudent(student_id: number, start_date: string, end_date: string): Observable<MissedClassTimes[]> {
		const req: MissedClassTimesReq = {
			student_id,
			start_date,
			end_date,
		};
		const data = this.http.post<MissedClassTimesResponse>('v2/schedules/GetMissedClassBreakdown', req, undefined, false).pipe(
			map((response) => {
				console.log('response', response);
				return response.MissedClassTimes;
			})
		);
		return data;
	}

	getScheduleGroupsForHolidayDeletion(
		startDate: string,
		scheduleListGroups: ScheduleGroupList[],
		scheduleForDates: ScheduleForDatesResponse
	): ScheduleGroupBellSchedule[] {
		return scheduleListGroups.map((sg, sgIndex) => {
			const previousBellScheduleIdInRotation = scheduleForDates.days[startDate]?.schedule_groups?.find((sgrp) => sgrp.id === sg.id)?.bell_schedule_id;
			if (previousBellScheduleIdInRotation) {
				let index = sg.rotation_bell_schedule_ids.findIndex((bsId) => bsId === previousBellScheduleIdInRotation) + 1;
				if (index > sg.rotation_bell_schedule_ids.length) {
					index = 0;
				}
				return {
					id: sg.id,
					bell_schedule_id: sg.rotation_bell_schedule_ids[index],
				};
			}
			return {
				id: sg.id,
				bell_schedule_id: sg.bell_schedule_groups[sgIndex].bell_schedules[0].id,
			};
		});
	}
}
