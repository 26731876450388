import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export const UNANIMATED_CONTAINER: ReplaySubject<boolean> = new ReplaySubject(1);
export const APPLY_ANIMATED_CONTAINER: Observable<boolean> = UNANIMATED_CONTAINER.asObservable();

@Injectable({
	providedIn: 'root',
})
export class ConsentMenuOverlay extends OverlayContainer {
	_defaultContainerElement!: HTMLElement;

	constructor(platform: Platform) {
		super(document, platform);
	}

	setContainer(container: HTMLElement) {
		this._defaultContainerElement = this._containerElement;
		this._containerElement = container;
	}

	restoreContainer() {
		this._containerElement = this._defaultContainerElement;
	}
}
