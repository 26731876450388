<div class="consent-wrapper" [style.background-color]="darkTheme.getBackground('low')" appCheckUserInput (inputValueEmit)="search($event)">
	<div class="heading" *ngIf="!!mainHeader || !!heading">
		<div class="search-icon" *ngIf="isHiddenSearchField" appCrossPointerEventTarget (pointerClickEvent)="isSearchField = !isSearchField">
			<img width="20" height="20" [src]="'./assets/Search Normal (Blue-Gray).svg'" alt="search" />
		</div>
		<div class="main" *ngIf="mainHeader">{{ mainHeader }}</div>
		<br *ngIf="mainHeader" />
		<div *ngIf="heading">{{ heading }}</div>
	</div>
	<mat-divider *ngIf="!!heading && (locations || schools || sortData || teachers || langs || plFrequencies)"></mat-divider>

	<div class="opt-container" *ngIf="locations || schools || sortData || teachers || langs || plFrequencies">
		<div class="search" *ngIf="isSearchField">
			<app-round-input
				[placeholder]="'Search ' + searchPlaceholder"
				[minWidth]="'280px'"
				[focused]="true"
				(ontextupdate)="searchObject($event)"></app-round-input>
		</div>

		<!------------------- Locations (Rooms) Dropdown ------------------->
		<div class="options-wrapper" [style.max-height]="optionsMaxHeight" *ngIf="locations" #optionsWrapper>
			<div class="option" *ngFor="let location of locations" #_option>
				<div
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value
					}"
					[style.background]="
						_option.pressed
							? 'rgba(127, 135, 157, .15)'
							: _option.hovered && location.room !== selectedLocation.room && location.title !== selectedLocation.title
							? 'rgba(127, 135, 157, .1)'
							: selectedLocation && location.room === selectedLocation.room && location.title === selectedLocation.title
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					appCrossPointerEventTarget
					(pointerClickEvent)="closeDropdown(location)"
					(pointerDownEvent)="_option.pressed = true"
					(pointerCancelEvent)="_option.pressed = false; _option.hovered = false"
					(mouseover)="_option.hovered = true"
					(mouseleave)="_option.hovered = false; _option.pressed = false">
					<div class="option-data_leftside">
						<span
							style="padding: 5px 0"
							[style.color]="
								selectedLocation && location.room === selectedLocation.room && location.title === selectedLocation.title
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							>{{ location.title + ' (' + location.room + ')' }}</span
						>
					</div>
					<div class="option-data_rightside">
						<div class="marked-room">
							<img
								*ngIf="selectedLocation ? location.room === selectedLocation.room && location.title === selectedLocation.title : false"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
					</div>
				</div>
				<!--      <div class="option-divider"></div>-->
			</div>
		</div>

		<!------------------- Schools Dropdown ------------------->
		<div class="options-wrapper" [style.padding]="isSearchField ? '10px 0 22px 0' : '22px 0'" [style.max-height]="optionsMaxHeight" *ngIf="schools">
			<div class="option" *ngFor="let school of schools | slice : 0 : 50" #schoolList>
				<div
					#_option
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value,
						'current-school': school.id === selectedSchool.id && school.name === selectedSchool.name
					}"
					[style.background]="
						_option.pressed
							? 'rgba(127, 135, 157, .15)'
							: _option.hovered
							? 'rgba(127, 135, 157, .1)'
							: school.id === selectedSchool.id && school.name === selectedSchool.name
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					appCrossPointerEventTarget
					(pointerClickEvent)="_matDialogRef.close(school)"
					(pointerDownEvent)="_option.pressed = true"
					(pointerCancelEvent)="_option.pressed = false; _option.hovered = false"
					(mouseover)="_option.hovered = true"
					(mouseleave)="_option.hovered = false; _option.pressed = false">
					<div>
						<span
							[style.color]="
								school.id === selectedSchool.id && school.name === selectedSchool.name
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							ignore
							>{{ school.name }}</span
						>
					</div>
					<div class="option-data_rightside">
						<div class="marked-room">
							<img
								*ngIf="school.id === selectedSchool.id && school.name === selectedSchool.name"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
					</div>
				</div>
				<!--      <div class="option-divider"></div>-->
			</div>
		</div>

		<!------------------- Languages Dropdown ------------------->
		<div class="options-wrapper" [style.padding]="isSearchField ? '10px 0 22px 0' : '22px 0'" [style.max-height]="optionsMaxHeight" *ngIf="langs">
			<div class="option" *ngFor="let lang of langs" #langList>
				<div
					#_option
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value,
						'option-disabled': isDisabledLang && lang !== 'en'
					}"
					[style.background]="
						_option.pressed
							? 'rgba(127, 135, 157, .15)'
							: _option.hovered
							? 'rgba(127, 135, 157, .1)'
							: lang === selectedLang
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					appCrossPointerEventTarget
					(pointerClickEvent)="_matDialogRef.close(lang)"
					(pointerDownEvent)="_option.pressed = true"
					(pointerCancelEvent)="_option.pressed = false; _option.hovered = false"
					(mouseover)="_option.hovered = true"
					(mouseleave)="_option.hovered = false; _option.pressed = false">
					<div>
						<span
							[style.color]="
								lang === selectedLang
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							>{{ countryCodes[lang] }}</span
						>
						<span *ngIf="lang === betaLanguage" class="beta ds-ml-15 ds-p-5">beta</span>
					</div>
					<div class="option-data_rightside">
						<div class="marked-room">
							<img
								*ngIf="lang === selectedLang"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
					</div>
				</div>
				<!--      <div class="option-divider"></div>-->
			</div>
		</div>

		<!------------------- Teachers Dropdown ------------------->
		<div class="options-wrapper" [style.max-height]="optionsMaxHeight" *ngIf="teachers && selectedTeacher" #optionsWrapper>
			<div class="option sort" *ngFor="let teacher of teachers">
				<div
					#_option
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value
					}"
					[style.background]="
						_option.pressed
							? 'rgba(127, 135, 157, .15)'
							: _option.hovered
							? 'rgba(127, 135, 157, .1)'
							: teacher.id === selectedTeacher.id
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					appCrossPointerEventTarget
					(pointerClickEvent)="_matDialogRef.close(teacher.id)"
					(pointerDownEvent)="_option.pressed = true"
					(pointerCancelEvent)="_option.pressed = false; _option.hovered = false"
					(mouseover)="_option.hovered = true"
					(mouseleave)="_option.hovered = false; _option.pressed = false">
					<div class="option-data_leftside" notranslate>
						<span
							[style.color]="
								teacher.id === selectedTeacher.id
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							>{{ teacher.display_name }}</span
						>
					</div>
					<div class="option-data_rightside">
						<div class="marked-room">
							<img
								*ngIf="teacher.id === selectedTeacher.id"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!------------------- Data? Dropdown ------------------->
		<div class="options-wrapper sort-wrapper" [style.max-height]="optionsMaxHeight" *ngIf="sortData">
			<div class="option sort" *ngFor="let sort of sortData" #optionsWrapper>
				<div
					#_st
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value
					}"
					[style.background]="
						_st.pressed
							? 'rgba(127, 135, 157, .15)'
							: _st.hovered
							? 'rgba(127, 135, 157, .1)'
							: sort.action === selectedSort
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					[style.padding]="optionPadding ? optionPadding : ''"
					appCrossPointerEventTarget
					(pointerClickEvent)="_matDialogRef.close(sort.action)"
					(pointerDownEvent)="_st.pressed = true"
					(pointerCancelEvent)="_st.pressed = false"
					(mouseover)="_st.hovered = true"
					(mouseleave)="_st.hovered = false; _st.pressed = false">
					<div class="option-data_leftside">
						<div
							*ngIf="sort?.header"
							class="tw-font-bold tw-text-sm"
							[style.color]="
								sort.action === selectedSort
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							">
							{{ sort.header }}
						</div>
						<span
							[style.color]="
								sort.action === selectedSort
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							>{{ sort.display }}</span
						>
					</div>
					<div class="option-data_rightside">
						<div class="marked-room">
							<img
								*ngIf="sort.action === selectedSort"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
					</div>
				</div>
				<mat-divider style="margin-top: 8px" *ngIf="sort.divider"></mat-divider>
			</div>
		</div>

		<!------------------- Pass Limit Frequencies Dropdown ------------------->
		<div class="options-wrapper sort-wrapper" [style.max-height]="optionsMaxHeight" *ngIf="plFrequencies">
			<div class="option sort" *ngFor="let f of plFrequencies" #optionsWrapper>
				<div
					#_st
					class="option-data"
					[ngClass]="{
						'option-wrapper': !darkTheme.isEnabled$.value,
						'option-wrapper__dark': darkTheme.isEnabled$.value
					}"
					[style.background]="
						_st.pressed
							? 'rgba(127, 135, 157, .15)'
							: _st.hovered
							? 'rgba(127, 135, 157, .1)'
							: f.value === selectedFrequency
							? darkTheme.getColor({ dark: '#134482', white: '#ECF1FF' })
							: 'transparent'
					"
					[style.padding]="optionPadding ? optionPadding : ''"
					[style.margin]="optionMargin ? optionMargin : ''"
					appCrossPointerEventTarget
					[style.cursor]="f.value === 'term' && (!termsSetup || termsOverlap) ? 'not-allowed' : 'pointer'"
					(pointerClickEvent)="f.value !== 'term' || (termsSetup && !termsOverlap) ? _matDialogRef.close(f.value) : ''"
					(pointerDownEvent)="f.value !== 'term' || (termsSetup && !termsOverlap) ? (_st.pressed = true) : ''"
					(pointerCancelEvent)="_st.pressed = false"
					(mouseover)="_st.hovered = true"
					(mouseleave)="_st.hovered = false; _st.pressed = false">
					<div class="option-data_leftside" style="display: flex">
						<div
							*ngIf="f?.header"
							class="tw-font-bold tw-text-sm"
							[style.color]="
								f.value === selectedFrequency
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							">
							{{ f.header }}
						</div>
						<span
							[style.color]="
								f.value === selectedFrequency
									? darkTheme.getColor({ dark: '#FFFFFF', white: '#1F195E' })
									: darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' })
							"
							>{{ f.display }}</span
						>
						<div
							*ngIf="f.value === 'term' && termsSetup"
							style="display: flex; align-items: center"
							[additionalOffsetY]="-30"
							[position]="'top'"
							customToolTip
							[contentTemplate]="termDatesTooltip">
							<img class="support-icon" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
						</div>
						<ng-template #termDatesTooltip>
							<div class="term-tooltip">
								<div class="header">
									<span class="title">
										{{ currentTerm?.name ? 'Current Term: ' : upcomingTerm?.name ? 'Upcoming Term: ' : 'No Upcoming Term' }}
									</span>
									<span>
										{{ currentTerm?.name ? currentTerm?.name : upcomingTerm?.name ? upcomingTerm?.name : '' }}
									</span>
								</div>
								<span class="dates" *ngIf="currentTerm || upcomingTerm">
									{{ (currentTerm ? currentTerm.start_date : upcomingTerm?.start_date) | date : 'MM/dd/yyyy' }}
									-
									{{ (currentTerm ? currentTerm.end_date : upcomingTerm?.end_date) | date : 'MM/dd/yyyy' }}
								</span>
								<span (click)="openIntercom()" style="color: white; text-decoration: underline; cursor: pointer">Edit Terms</span>
							</div>
						</ng-template>
					</div>
					<div class="option-data_rightside" style="display: flex; align-items: center">
						<div class="marked-room">
							<img
								*ngIf="f.value === selectedFrequency && !(f.value === 'term' && (!termsSetup || (!currentTerm && !upcomingTerm)))"
								[style.width]="'20px'"
								[style.height]="'20px'"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' }) | resolveAsset"
								alt="Check (Navy).svg" />
						</div>
						<div
							*ngIf="f.value === 'term' && (!termsSetup || (!currentTerm && !upcomingTerm))"
							style="display: flex; align-items: center; column-gap: 1.5px"
							customToolTip
							[contentTemplate]="termSetupTooltip">
							<img class="support-icon" [src]="'./assets/Exclamation Mark Circle (Gray-500).svg' | resolveAsset" />
							<span (click)="openIntercom(); $event.stopPropagation()" style="text-decoration: underline; cursor: pointer; color: #7083a0">
								Needs Setup
							</span>
						</div>
						<ng-template #termSetupTooltip>
							<div class="term-tooltip">
								<span> Click to learn more about setting up terms.</span>
							</div>
						</ng-template>
						<div
							*ngIf="f.value === 'term' && termsOverlap"
							style="display: flex; align-items: center; column-gap: 1.5px"
							customToolTip
							[contentTemplate]="termsOverlapTooltip">
							<img class="support-icon" [src]="'./assets/Exclamation Mark Circle (Gray-500).svg' | resolveAsset" />
							<a
								id="overlap-link"
								href="https://articles.smartpass.app/en/articles/6856267-pass-limits#h_989a1839e3"
								style="color: #7083a0"
								target="_blank"
								(click)="$event.stopPropagation()">
								Overlap
							</a>
						</div>
						<ng-template #termsOverlapTooltip>
							<div class="term-tooltip">
								<span> Click to get support for overlapping terms.</span>
							</div>
						</ng-template>
					</div>
				</div>
				<mat-divider style="margin-top: 8px" *ngIf="f.divider"></mat-divider>
			</div>
		</div>
	</div>
</div>
