import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DeviceDetection } from '../device-detection.helper';
import { HttpService } from './http-service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class AllowMobileService {
	/**
	 * Emits `true` when the application should be restricted because
	 * the user is using a mobile device, and the school doesn't allow it.
	 */
	canUseMobile$ = combineLatest([this.userService.userData.asObservable(), this.httpService.currentSchool$]).pipe(
		map(([user, school]) => {
			return !DeviceDetection.isMobile() || !user?.isStudent() || (school?.student_can_use_mobile ?? false);
		}),
		// share replay maintains backwards compatibility with previous behavior
		shareReplay({
			refCount: true,
			bufferSize: 1,
		})
	);

	constructor(private userService: UserService, private httpService: HttpService) {}
}
