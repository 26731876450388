import { zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { FlexPeriod } from '../../flex-period.service';
import { SchoolActivity, SchoolActivityInstance } from '../../services/school-activity.service';
import { spPost } from '../lib/network';
import { isEqualOmit } from '../lib/util';

export interface ActivityWithInstance {
	activity: SchoolActivity;
	instance: SchoolActivityInstance;
}

interface IUseAIBP {
	day: Date;
	flexPeriod: FlexPeriod;
	timezone: string;
	schoolId: number;
	refreshKey?: number;
}

export function useActivityInstancesByPeriodAndFillExtra({ day, flexPeriod, timezone, schoolId, refreshKey }: IUseAIBP) {
	const [activityInstances, setActivityInstances] = useState<ActivityWithInstance[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const utcDateTimeZone = useCallback((time, zone) => {
		if (zone) {
			return zonedTimeToUtc(time, zone);
		}
		return time;
	}, []);

	const fetchActivities = useCallback(async () => {
		return spPost<SchoolActivity[]>(
			'v2/school_activities/list',
			{ flex_period_id: flexPeriod.id },
			{
				'X-School-Id': String(schoolId),
			}
		);
	}, [schoolId, flexPeriod.id]);

	const getActivityInstances = async () => {
		setIsLoading(true);
		try {
			const realActivities = await fetchActivities();
			const beginningOfDay = new Date(day);
			beginningOfDay.setHours(0, 0, 0, 0);
			const localBeginningOfDay = new Date(beginningOfDay.toLocaleString());
			const endOfDay = new Date(day);
			endOfDay.setHours(23, 59, 59, 999);
			const localEndOfDay = new Date(endOfDay.toLocaleString());

			const startDateTime = new Date(day);
			const endDateTime = new Date(day);
			if (flexPeriod && flexPeriod.schedules) {
				flexPeriod.schedules.forEach((schedule) => {
					if (schedule.days_of_week.find((day) => day === startDateTime.getDay())) {
						startDateTime.setHours(schedule.start_hour, schedule.start_minute);
						endDateTime.setHours(schedule.end_hour, schedule.end_minute);
					}
				});
			}

			const filteredActivities = realActivities.filter(
				(activity) => activity.flex_period_id === flexPeriod?.id && activity.created_at <= day.toISOString()
			);

			const instances = await spPost<SchoolActivityInstance[]>(
				'v2/school_activities/instances/list',
				{
					start_time: localBeginningOfDay.toISOString(),
					end_time: localEndOfDay.toISOString(),
				},
				{
					'X-School-Id': String(schoolId),
				}
			);

			const newActInstas = filteredActivities
				.flatMap((activity) => {
					const foundInstance = instances.find((instance) => instance.activity_id === activity.id);
					if (foundInstance) {
						return { activity: activity, instance: foundInstance };
					} else if (activity.state === 'flex_recurring' && new Date(activity.created_at) <= localEndOfDay) {
						const newInstance = {
							id: 0,
							start_time: utcDateTimeZone(startDateTime, timezone).toISOString(),
							end_time: utcDateTimeZone(endDateTime, timezone).toISOString(),
							activity_id: activity.id,
							user_id: 0,
							school_id: 0,
							created_at: new Date().toDateString(),
							updated_at: new Date().toDateString(),
							state: 'scheduled',
							current_num_attendees: 0,
						};
						return { activity: activity, instance: newInstance };
					}
					return [];
				})
				.filter((aci: ActivityWithInstance) => aci.instance.state !== 'canceled')
				.sort((a, b) => a.activity.name.localeCompare(b.activity.name));

			if (!isEqualOmit(newActInstas, activityInstances, ['profile_picture'])) {
				setActivityInstances(newActInstas);
			}
		} catch (error) {
			setError(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getActivityInstances();
	}, [day, fetchActivities, refreshKey]);

	return { activityInstances, isLoading, error };
}
