import * as React from 'react';
import { exportAttendeeByFlex } from '../../../lib/network/activity-attendees';
import { downloadBlob } from '../../../lib/network/download-blob';
import { useIntervalAsyncFunction } from '../../../hooks';
import { HoverText } from '../../hover-text/hover-text';

interface IHeaderStatProps {
	active: boolean;
	attending: boolean;
	num: number;
	schoolId: number;
	flexPeriodId: number;
	date: Date;
	isLoading: boolean;
	fileName: string;
}

export function HeaderStat({ active, attending, num, schoolId, flexPeriodId, date, isLoading, fileName }: IHeaderStatProps): React.ReactElement {
	const backgroundColor = active ? (attending ? 'tw-bg-green-100' : 'tw-bg-red-100') : 'tw-bg-gray-100';

	const downloadCsv = async () => {
		const blob = await exportAttendeeByFlex(schoolId, flexPeriodId, date, attending);
		downloadBlob(blob, `${attending ? '' : 'Not '}In Activities for ${fileName}.csv`);
	};
	const handleClick = useIntervalAsyncFunction(downloadCsv, 1000);

	const attendanceStatus = attending ? '' : 'Not ';
	const shouldDownload = !isLoading && num > 0;
	const downloadIconVisibility = shouldDownload ? 'tw-visible' : 'tw-hidden';

	return (
		<HoverText
			message={shouldDownload ? 'Download CSV' : ''}
			onClick={() => {
				if (shouldDownload) {
					handleClick();
				}
			}}
			className={`
				tw-justify-center
				tw-flex
				tw-flex-col
				tw-gap-y-1
				tw-items-center
				tw-rounded-lg
				tw-h-20
				tw-relative
				${shouldDownload ? 'tw-cursor-pointer' : ''}
				${backgroundColor}
			`}>
			{shouldDownload && (
				<img
					className={`
						tw-animate-fadein-700
						tw-absolute
						tw-w-4
						tw-right-2
						tw-top-2
						${downloadIconVisibility}
					`}
					src="./assets/Download circle (Blue-Gray).svg"
					alt="Download Student Records CSV"
				/>
			)}
			<div className="tw-whitespace-nowrap tw-text-2xl tw-font-bold tw-h-8 tw-text-navy-500">
				{!isLoading && <div className="tw-animate-fadein-700">{num}</div>}
			</div>
			<div className="tw-whitespace-nowrap tw-text-navy-500 tw-text-sm tw-font-normal">
				Students <b>{attendanceStatus}</b>in Activities
			</div>
		</HoverText>
	);
}
