import { Component } from '@angular/core';
import { PassLimitInfo } from '../models/HallPassLimits';
import { Location } from '../models/Location';
import { NoFlyTime } from '../models/NoFlyTime';
import { User } from '../models/User';
import { Navigation } from './main-hallpass--form/main-hall-pass-form.component';

// TODO: Get proper types for fields and complete fields
export interface CreatePassDialogData {
	FORM_STATE: Navigation;
	forLater: boolean;
	forStaff: boolean;
	forInput: boolean;
	kioskMode: boolean;
	forKioskMode: boolean;
	fromAdmin: boolean;
	adminSelectedStudent: User | null;
	kioskModeSelectedUser: User[];
	teacher: User;
	kioskModeRoom: Location;
	originalFromLocation: Location;
	originalToLocation: Location;
	gradient: string;
	request: any;
	missedRequest: boolean;
	resend_request: boolean;
	request_time: any;
	isDeny: boolean;
	entryState: { step: number; state: number };
	hasClose: boolean;
	passLimitInfo: PassLimitInfo;
	noFlyTimes: NoFlyTime[];
	currentNoFlyTimeIndex: number;
	noFlyTimeActive?: boolean;
	currentNoFlyEndTimeString?: string;
	openedFrom?: string;
	openRequestPin?: boolean;
	lockOriginLocation?: boolean;
	lockDestinationLocation?: boolean;
}

@Component({
	selector: 'app-create-hallpass-forms',
	templateUrl: './create-hallpass-forms.component.html',
	styleUrls: ['./create-hallpass-forms.component.scss'],
})
export class CreateHallpassFormsComponent {}
