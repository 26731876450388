import { Injectable } from '@angular/core';
import { AllowIn, ShortcutEventOutput, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class KeyboardShortcutsService {
	shortcuts: ShortcutInput[] = [];

	onPressKeyEvent$: Subject<ShortcutEventOutput> = new Subject<ShortcutEventOutput>();

	initialize() {
		const shortcut = (keys: string[], options: Partial<ShortcutInput> = {}): ShortcutInput => ({
			key: keys,
			command: (event: ShortcutEventOutput): void => {
				// Stop capturing command- or alt- key combinations
				if (event.event.metaKey) {
					return;
				}

				// Prevent default defaults to true if handled
				const preventDefault = options.preventDefault == undefined ? true : options.preventDefault;
				if (preventDefault) {
					event.event.preventDefault();
				}
				this.onPressKeyEvent$.next(event);
			},
			...options,
		});

		this.shortcuts.push(
			shortcut(['s']),
			shortcut(['n']),
			shortcut(['f']),
			shortcut([',']),
			shortcut(['a']),
			shortcut(['d']),
			shortcut(['e']),
			shortcut(['r']),
			shortcut(['1']),
			shortcut(['2']),
			shortcut(['3']),
			shortcut(['4']),
			shortcut(['5']),
			shortcut(['6']),
			shortcut(['7']),
			shortcut(['8']),
			shortcut(['9']),
			shortcut(['0']),
			shortcut(['tab'], { allowIn: [AllowIn.Input], preventDefault: false }),
			shortcut(['enter'], { allowIn: [AllowIn.Input] })
		);
	}

	add(elem: ShortcutInput) {
		this.shortcuts.push(elem);
	}
}
