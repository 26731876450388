import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { School } from '../models/School';
import { HttpService } from './http-service';

/**
 * Add any future feature flags to this enum
 */
export enum FLAGS {
	EncounterDetection = 'feature_flag_encounter_detection',
	DigitalId = 'feature_flag_digital_id',
	PhoneAccess = 'feature_flag_phone',
	ParentAccounts = 'feature_flag_parent_accounts',
	WaitInLine = 'feature_flag_wait_in_line',
	ShowWaitInLine = 'feature_flag_show_wait_in_line',
	AbbreviateLastName = 'feature_flag_new_abbreviation',
	ShowStreaks = 'feature_flag_streaks',
	RenewalChecklist = 'feature_flag_renewal_checklist',
	TeacherReviews = 'teacher_reviews',
	ReferralProgramme = 'feature_flag_referral_program',
	// V2 Feature Flags
	YearInReview = 'year_in_review',
	NotificationSounds = 'feature_flag_alert_sounds',
	EmergencyMode = 'emergency_mode',
	FlexPeriods = 'flex_periods',
	Calendar = 'calendar',
	Attendance = 'attendance',
	DirectIntegration = 'direct_integration',
	CalenderMonthDayView = 'month_day_view',
	FeatureFlagPanel = 'feature_flag_panel',
	DevPlayground = 'dev_playground',
	Schedules = 'schedules',
	OneClickApprove = 'one_click_approve',
	EncounterPreventionOverride = 'encounter_prevention_override',
	ClosestRoom2 = 'closest_room',
	OriginWaitInLine = 'origin_wait_in_line',
	MissedClassTime = 'missed_class_time',
	WaitInLineUIRefresh = 'wil_ui_refresh',
	LocationFormsRefactor = 'location_forms_refactor',
	CombinedTeacherAdmin = 'combined_teacher_admin',
	ActivityManagement = 'activity_management',
	ActivitiesWithoutPasses = 'activity_without_pass',
	GetStarted = 'getting_started',
}

// TODO: Replace individual feature flag functions with this service
@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private school: School;
	private flagsV2$: Observable<string[]>;

	constructor(private http: HttpService) {
		this.flagsV2$ = this.http.currentSchool$.pipe(
			shareReplay({ bufferSize: 1, refCount: false }),
			filter((s) => !!s),
			map((s) => s.feature_flags_v2)
		);

		this.http.currentSchool$.subscribe({
			next: (s) => (this.school = s),
		});
	}

	isFeatureEnabled(featureFlag: FLAGS): boolean {
		return !!this.school?.[featureFlag];
	}

	isFeatureEnabledV2(featureFlag: FLAGS): boolean {
		if (!this.school || !this.school.feature_flags_v2) {
			return false;
		}

		return this.school.feature_flags_v2.includes(featureFlag);
	}

	isFeatureEnabledV2$(flag: FLAGS): Observable<boolean> {
		return this.flagsV2$.pipe(map((flags) => flags.includes(flag)));
	}

	attendancePlan(): 'no_access' | 'yes' {
		if (this.school.attendance_access == 'yes') {
			return 'yes';
		}

		return 'no_access';
	}

	listFeatureFlags(): Observable<ListFeatureFlagsResp> {
		return this.http.post<ListFeatureFlagsResp>('v2/school/feature_flags/list', {}, undefined, false);
	}

	updateFeatureFlags(req: UpdateFeatureFlagsReq): Observable<ListFeatureFlagsResp> {
		return this.http.post<ListFeatureFlagsResp>('v2/school/feature_flags/update', req, undefined, false);
	}
}

export interface Flag {
	internal_name: string;
	title: string;
	description: string;
	value: FlagValue;
}

export interface FlagValue {
	enabled: boolean;
}

export interface ListFeatureFlagsResp {
	flags: Flag[];
}

export interface UpdateFlag {
	internal_name: string;
	value: FlagValue;
}

export interface UpdateFeatureFlagsReq {
	flags: UpdateFlag[];
}
