import { AgmCoreModule } from '@agm/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, Component, DoBootstrap, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { random } from 'lodash';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { CookieService } from 'ngx-cookie-service';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { environment } from '../environments/environment';
import { ActivitySignUpComponent } from './activity-sign-up/activity-sign-up.component';
import { ActivityComponent } from './activity-sign-up/activity/activity.component';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { BackToSchoolTeacherNuxComponent } from './back-to-school/back-to-school-teacher-nux/back-to-school-teacher-nux.component';
import { ConsentMenuOverlay } from './consent-menu-overlay';
import { CoreModule } from './core/core.module';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { IS_USING_NEW_AUTHENTICATION, provideErrorHandler } from './error-handler';
import { IdCardStandaloneGuard } from './guards/id-card-standalone.guard.service';
import { IsAdminGuard } from './guards/is-admin.guard';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { IsParentGuard } from './guards/is-parent.guard';
import { IsStudentOrTeacherGuard } from './guards/is-student-or-teacher.guard';
import { NotKioskModeGuard } from './guards/not-kiosk-mode.guard';
import { OldAuthenticatedGuard } from './guards/old-authenticated.guard';
import { RootRedirectGuard } from './guards/root-redirect.guard';
import { IdCardStandaloneComponent } from './id-card-standalone/id-card-standalone.component';
import { IdcardOverlayContainerComponent } from './idcard-overlay-container/idcard-overlay-container.component';
import { MenuDialogComponent } from './menu-dialog/menu-dialog.component';
import { NextReleaseModule } from './next-release/next-release.module';
import { AccountsEffects } from './ngrx/accounts/effects/accounts.effects';
import { AdminsEffects } from './ngrx/accounts/nested-states/admins/effects';
import { AllAccountsEffects } from './ngrx/accounts/nested-states/all-accounts/effects';
import { AssistantsEffects } from './ngrx/accounts/nested-states/assistants/effects';
import { CountAccountsEffects } from './ngrx/accounts/nested-states/count-accounts/effects';
import { ParentsEffects } from './ngrx/accounts/nested-states/parents/effects';
import { StudentsEffects } from './ngrx/accounts/nested-states/students/effects';
import { TeachersEffects } from './ngrx/accounts/nested-states/teachers/effects';
import { reducers } from './ngrx/app-state/app-state';
import { ColorsEffects } from './ngrx/color-profiles/effects';
import { ContactTraceEffects } from './ngrx/contact-trace/effects';
import { DashboardEffects } from './ngrx/dashboard/effects';
import { EncounterDetectionEffects } from './ngrx/encounter-detection';
import { ExclusionGroupsEffects } from './ngrx/encounters-prevention/excusion-groups/effects';
import { FavoriteLocationsEffects } from './ngrx/favorite-locations/effects';
import { IntrosEffects } from './ngrx/intros';
import { LocationsEffects } from './ngrx/locations/effects';
import { ProcessEffects } from './ngrx/onboard-process/effects';
import { FiltersEffects } from './ngrx/pass-filters/effects';
import { PassLikeCollectionEffects } from './ngrx/pass-like-collection/effects/pass-like-collection.effects';
import { ActivePassesEffects } from './ngrx/pass-like-collection/nested-states/active-passes/effects';
import { ExpiredPassesEffects } from './ngrx/pass-like-collection/nested-states/expired-passes/effects';
import { FromLocationPassesEffects } from './ngrx/pass-like-collection/nested-states/from-location/effects';
import { FuturePassesEffects } from './ngrx/pass-like-collection/nested-states/future-passes/effects';
import { HallMonitorPassesEffects } from './ngrx/pass-like-collection/nested-states/hall-monitor-passes/effects';
import { InvitationsEffects } from './ngrx/pass-like-collection/nested-states/invitations/effects';
import { RequestsEffects } from './ngrx/pass-like-collection/nested-states/requests/effects';
import { ToLocationPassesEffects } from './ngrx/pass-like-collection/nested-states/to-location/effects';
import { PassLimitEffects } from './ngrx/pass-limits/effects';
import { PassStatsEffects } from './ngrx/pass-stats/effects';
import { PassesEffects } from './ngrx/passes';
import { PinnablesEffects } from './ngrx/pinnables/effects';
import { ProfilePicturesEffects } from './ngrx/profile-pictures/effects';
import { QuickPreviewPassesEffects } from './ngrx/quick-preview-passes/effects';
import { ReportsEffects } from './ngrx/reports';
import { RepresentedUsersEffects } from './ngrx/represented-users/effects';
import { SchoolsEffects } from './ngrx/schools/effects';
import { SmartpassSearchEffects } from './ngrx/smartpass-search/effects';
import { StudentGroupsEffects } from './ngrx/student-groups/effects';
import { TeacherLocationsEffects } from './ngrx/teacherLocations/effects';
import { ToastEffects } from './ngrx/toast/effects';
import { UserEffects } from './ngrx/user/effects';
import { OutageWarningComponent } from './outage-warning/outage-warning.component';
import { ProgressInterceptor } from './progress-interceptor';
import { ScrollHolderDirective } from './scroll-holder.directive';
import { AuthenticationService } from './services/authentication.service';
import { OldLoginService } from './services/old-login.service';
import { StorageService } from './services/storage.service';
import { SharedModule } from './shared/shared.module';
import { StreaksDialogComponent } from './streaks-dialog/streaks-dialog.component';
import { SupportButtonComponent } from './support/support-button/support-button.component';
import { TeacherToggleBarComponent } from './teacher-toggle-bar/teacher-toggle-bar.component';
import { TrampolineRootComponent } from './trampoline/trampoline-root.component';
import { TrampolineModule } from './trampoline/trampoline.module';
import { TrialBarComponent } from './trial-bar/trial-bar.component';

// uncomment when app uses formatDate and so on
//import {LOCALE_ID} from '@angular/core';
//import {HttpService} from './services/http-service';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: 'horizontal',
	slidesPerView: 'auto',
};

const IS_USING_NEW_AUTHENTICATION_KEY = 'is_using_new_authentication';
function decideIsUsingNewAuthentication(): boolean {
	try {
		const decidedValue = localStorage.getItem(IS_USING_NEW_AUTHENTICATION_KEY);
		if (decidedValue === null) {
			const newDecidedValue = random(0, 100, false) <= 10;
			localStorage.setItem(IS_USING_NEW_AUTHENTICATION_KEY, newDecidedValue.toString());
			return newDecidedValue;
		}
		return decidedValue === 'true';
	} catch {
		return false;
	}
}

export const isUsingNewAuthentication = decideIsUsingNewAuthentication();
const AuthenticatedGuard = isUsingNewAuthentication ? IsAuthenticatedGuard : OldAuthenticatedGuard;

const appRoutes: Routes = [
	{
		path: '',
		loadChildren: () => import('app/auth/login.module').then((m) => m.LoginModule),
		data: { hideSchoolToggleBar: true },
	},
	{
		path: 'home',
		canActivate: [RootRedirectGuard],
		component: Component,
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'google_oauth',
		loadChildren: () => import('app/auth/login.module').then((m) => m.LoginModule),
		data: { hideSchoolToggleBar: true },
	},
	{
		path: 'classlink_oauth',
		loadChildren: () => import('app/auth/login.module').then((m) => m.LoginModule),
		data: { hideSchoolToggleBar: true },
	},
	{
		path: 'main',
		canActivate: [AuthenticatedGuard, IsStudentOrTeacherGuard, IdCardStandaloneGuard],
		loadChildren: () => import('app/main/main.module').then((m) => m.MainModule),
		data: {
			hubspot: true,
			authFree: false,
		},
	},
	{
		path: 'admin',
		canActivate: [AuthenticatedGuard, NotKioskModeGuard, IsAdminGuard],
		loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule),
		data: {
			hubspot: true,
			authFree: false,
		},
	},
	{
		path: 'parent',
		canActivate: [AuthenticatedGuard, IsParentGuard],
		loadChildren: () => import('app/parent/parent.module').then((m) => m.ParentModule),
		data: {
			hideSchoolToggleBar: true,
			hubspot: false,
			authFree: true,
		},
	},
	{
		path: 'sign-out',
		loadChildren: () => import('app/sign-out/sign-out.module').then((m) => m.SignOutModule),
	},
	{
		path: 'forms',
		loadChildren: () => import('app/forms/forms.module').then((m) => m.FormsModule),
		data: { hideSchoolToggleBar: true, hubspot: false, authFree: true, hideScroll: false },
	},
	{
		path: 'links',
		loadChildren: () => import('app/weblinks/weblinks.module').then((m) => m.WeblinksModule),
		data: { hideSchoolToggleBar: true, hubspot: false, authFree: true, hideScroll: false },
	},
	{
		path: 'mobile-restriction',
		loadChildren: () => import('app/mobile-restriction/mobile-restriction.module').then((m) => m.MobileRestrictionModule),
	},
	{ path: 'id-card', canActivate: [IdCardStandaloneGuard], component: IdCardStandaloneComponent },
	{ path: 'trampoline', loadChildren: () => import('./trampoline/trampoline.module').then((m) => m.TrampolineModule) },
	{ path: '**', redirectTo: 'main/passes', pathMatch: 'full' },
];

@NgModule({
	declarations: [
		AppComponent,
		ScrollHolderDirective,
		SupportButtonComponent,
		CustomToastComponent,
		IdcardOverlayContainerComponent,
		StreaksDialogComponent,
		TrialBarComponent,
		ActivitySignUpComponent,
		ActivityComponent,
		IdCardStandaloneComponent,
		BackToSchoolTeacherNuxComponent,
		TeacherToggleBarComponent,
		MenuDialogComponent,
		OutageWarningComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		SwiperModule,
		CoreModule,
		NextReleaseModule,
		KeyboardShortcutsModule.forRoot(),
		MatDialogModule,
		MatButtonModule,
		RouterModule.forRoot(appRoutes, {
			enableTracing: false,
			relativeLinkResolution: 'legacy',
		}),
		AngularFireModule.initializeApp(environment.firebase, 'notifyhallpass'),
		AngularFireMessagingModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyB-PvmYU5y4GQXh1aummcUI__LNhCtI68o',
			libraries: ['places'],
		}),
		StoreModule.forRoot(reducers),
		EffectsModule.forRoot([
			ReportsEffects,
			PinnablesEffects,
			AccountsEffects,
			AllAccountsEffects,
			AdminsEffects,
			TeachersEffects,
			AssistantsEffects,
			StudentsEffects,
			CountAccountsEffects,
			TeacherLocationsEffects,
			DashboardEffects,
			PassStatsEffects,
			StudentGroupsEffects,
			LocationsEffects,
			FavoriteLocationsEffects,
			ColorsEffects,
			SchoolsEffects,
			UserEffects,
			ProcessEffects,
			PassLimitEffects,
			PassesEffects,
			ContactTraceEffects,
			IntrosEffects,
			PassLikeCollectionEffects,
			InvitationsEffects,
			RequestsEffects,
			ExpiredPassesEffects,
			FuturePassesEffects,
			ActivePassesEffects,
			ToLocationPassesEffects,
			FromLocationPassesEffects,
			HallMonitorPassesEffects,
			FiltersEffects,
			RepresentedUsersEffects,
			QuickPreviewPassesEffects,
			ProfilePicturesEffects,
			ExclusionGroupsEffects,
			ToastEffects,
			SmartpassSearchEffects,
			EncounterDetectionEffects,
			ParentsEffects,
		]),
		StoreDevtoolsModule.instrument({}),
		HammerModule,
		SharedModule,
		TrampolineModule,
		PlatformModule,
	],
	providers: [
		{ provide: IS_USING_NEW_AUTHENTICATION, useValue: isUsingNewAuthentication },
		CookieService,
		AuthenticationService,
		{ provide: OverlayContainer, useClass: ConsentMenuOverlay },
		{ provide: HTTP_INTERCEPTORS, useExisting: AuthenticationService, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
			deps: [StorageService, CookieService, OldLoginService, Router],
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
		// uncomment when app uses formatDate and so on
		/*{
      provide: LOCALE_ID,
      deps:[HttpService],
      useFactory: (hs: HttpService) => hs.LocaleID,
    }*/ provideErrorHandler(),
	],
	// Boot strapping happens in main.ts
	bootstrap: [],
})
export class AppModule implements DoBootstrap {
	ngDoBootstrap(app: ApplicationRef) {
		if (window.location.pathname.startsWith('/trampoline')) {
			console.log('trampoline bootstrap');
			const appRoot = document.createElement('app-trampoline-root', {});
			document.body.appendChild(appRoot);
			app.bootstrap(TrampolineRootComponent);
			return;
		}

		console.log('normal bootstrap');
		const appRoot = document.createElement('app-root', {});
		appRoot.style.flexGrow = '1';
		appRoot.style.display = 'flex';
		appRoot.style.flexDirection = 'column';
		appRoot.className = 'app-root';
		document.body.appendChild(appRoot);

		app.bootstrap(AppComponent);
	}
}
