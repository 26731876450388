import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DeviceDetection } from 'app/device-detection.helper';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class RootRedirectGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService, private userService: UserService) {}

	canActivate() {
		return this.authenticationService.isAuthenticated$.pipe(
			switchMap((isAuthenticated) => {
				if (!isAuthenticated) {
					// If the user is not yet authenticated, send them to the login forms.
					return of(this.router.parseUrl('/'));
				}

				return this.userService.user$.pipe(
					map((user) => {
						if (user.isParent()) {
							return this.router.parseUrl('/parent');
						}

						// Old Logic
						// Admins go to Admin, unless they are on mobile.
						if (user.isAdmin() && !DeviceDetection.isMobile()) {
							return this.router.parseUrl('/admin');
						}

						return this.router.parseUrl('/main');
					})
				);
			})
		);
	}
}
