import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { combineLatest, iif, interval, NEVER, Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';
import { Util } from '../../Util';
import { BellScheduleGroup, ScheduleForDatesResponse } from '../models/Schedule';
import { ClassesService, SPClassWithUsers } from './classes.service';
import { HallPassesService } from './hall-passes.service';
import { AgendaResponse, ScheduleService } from './schedule.service';
import { TimeService } from './time.service';

// todo does this need to use jitter and exponential backoff?
export const DATA_RELOAD_INTERVAL = 900000; // every 15 minutes

@Injectable({
	providedIn: 'root',
})
export class BootstrapService {
	bootstrapped$: Subject<boolean> = new Subject<boolean>();
	bootstrapError$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private classesService: ClassesService,
		private scheduleService: ScheduleService,
		private hallPassService: HallPassesService,
		private timeService: TimeService
	) {}

	loadDataForStaff(userId: number): Observable<void> {
		this.hallPassService.getPinnablesRequestV2();
		const scheduleForDates$ = this.scheduleService.dayChange$.pipe(
			switchMap((date) => {
				return this.scheduleService.getScheduleForDates(date, date).pipe(
					catchError(() => of<ScheduleForDatesResponse | null>(null)),
					tap((data) => {
						if (Array.isArray(data)) {
							this.scheduleService.mySchedules$.next(data);
						}
					})
				);
			})
		);
		const getAgendaForDates$ = this.scheduleService.dayChange$.pipe(
			switchMap((date) => {
				const endDate = cloneDeep(date);
				endDate.setDate(date.getDate() + 1);
				return this.scheduleService.getAgendaForDates(userId, date, endDate).pipe(
					catchError(() => of<AgendaResponse | null>(null)),
					tap((data: AgendaResponse | null) => this.scheduleService.mySchedulesAgenda$.next(data))
				);
			})
		);

		return interval(DATA_RELOAD_INTERVAL).pipe(
			startWith(0),
			switchMap(() => scheduleForDates$),
			switchMap(() => getAgendaForDates$),
			switchMap(() => {
				const currentScheduleDate = this.scheduleService.dayChange$.getValue();
				const dateNow = new Date();

				if (currentScheduleDate.getDate() !== dateNow.getDate()) {
					this.scheduleService.dayChange$.next(dateNow);
				}
				this.scheduleService.isWeekend$.next(Util.isWeekendDate(dateNow));
				return of(undefined);
			}),
			tap(() => this.bootstrapped$.next(true))
		);
	}

	// When implemented, this method should be called in the app component after the user
	// has been logged in--and also on a specific interval.
	loadDataForStudent(userId: number): Observable<SPClassWithUsers[] | null> {
		this.hallPassService.getPinnablesRequestV2();

		const scheduleForDates$ = this.scheduleService.dayChange$.pipe(
			switchMap((date) =>
				this.scheduleService.getScheduleForDates(date, date).pipe(
					catchError((_error) => {
						return of<ScheduleForDatesResponse | null>(null);
					}),
					tap((data) => {
						if (Array.isArray(data)) {
							this.scheduleService.mySchedules$.next(data);
						}
					})
				)
			)
		);

		let scheduleGroups: BellScheduleGroup[] = [];
		const getAgendaForDates$ = this.scheduleService.schedulesList$.pipe(
			switchMap((schedules) => {
				if (schedules.length > 0) {
					scheduleGroups = schedules[0].schedule_groups;
				}
				return this.scheduleService.dayChange$;
			}),
			switchMap((date) => {
				const endDate = cloneDeep(date);
				endDate.setDate(date.getDate() + 1);

				return this.scheduleService.getAgendaForDates(userId, date, endDate).pipe(
					catchError(() => {
						return of<AgendaResponse>(null);
					}),
					tap((data) => {
						this.scheduleService.mySchedulesAgenda$.next(data);
						this.setScheduleGroupIndex(data, scheduleGroups);
					})
				);
			})
		);

		return interval(DATA_RELOAD_INTERVAL).pipe(
			startWith(0),
			switchMap(() => {
				const currentScheduleDate = this.scheduleService.dayChange$.getValue();
				const dateNow = this.timeService.nowDate();
				if (currentScheduleDate.getDate() !== dateNow.getDate()) {
					this.scheduleService.dayChange$.next(dateNow);
				}
				return combineLatest([scheduleForDates$, getAgendaForDates$]);
			}),
			// we need the pinnables for room icons
			switchMap(() => combineLatest([scheduleForDates$, getAgendaForDates$])),
			switchMap(([_]) => {
				return this.classesService.listClassesByUserId(userId, 'active').pipe(
					catchError((error) => {
						this.bootstrapError$.next(error);
						return of(null);
					}),
					tap((data) => {
						this.classesService.allClassesDataSource$.next(data);
						this.bootstrapped$.next(true);
					})
				);
			})
		);
	}

	//load agenda data for another user that is not the current one
	loadDataForOther(userId: number): Observable<AgendaResponse> {
		let scheduleGroups: BellScheduleGroup[] = [];
		return this.scheduleService.schedulesList$.pipe(
			switchMap((schedules) => {
				if (schedules.length > 0) {
					scheduleGroups = schedules[0].schedule_groups;
				}
				const endDate = new Date();
				endDate.setDate(endDate.getDate() + 1);
				return iif(() => schedules.length > 0, this.scheduleService.getAgendaForDates(userId, new Date(), endDate), NEVER);
			}),
			tap((data) => {
				this.scheduleService.otherSchedulesAgenda$.next(data); // Process agenda data internally
				this.setScheduleGroupIndex(data, scheduleGroups);
			})
		);
	}

	setScheduleGroupIndex(data: AgendaResponse, scheduleGroups: BellScheduleGroup[]): void {
		const day = moment().format('YYYY-MM-DD');
		const currentTime = this.timeService.nowDate();
		const currentClassAgenda = data.days[day].class_agendas.filter((ca) => Util.isTimeBetween(currentTime, ca.start_time, ca.end_time));
		const sgIndex = scheduleGroups.findIndex((sg) => sg.id === currentClassAgenda[0]?.schedule_group_id);
		this.scheduleService.scheduleGroupIndex$.next({
			manualTrigger: false,
			index: sgIndex !== -1 ? sgIndex : 0,
		});
	}
}
