import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { HallPassFilter, LiveDataService } from '../live-data/live-data.service';
import { HttpService } from './http-service';
import { HallPassLimit, IndividualPassLimit, IndividualPassLimitCollection, PassLimitInfo, StudentPassLimit } from '../models/HallPassLimits';
import { User } from '../models/User';
import { UserService } from './user.service';
import { ScheduleService } from './schedule.service';

const PASS_LIMIT_ENDPOINT = 'v1/pass_limits';

@Injectable({
	providedIn: 'root',
})
export class PassLimitService {
	individualLimitUpdate$: Subject<undefined> = new Subject<undefined>();

	private passLimitStudent$ = new BehaviorSubject<User>(null);
	private passLimitInfo$ = new BehaviorSubject<PassLimitInfo>(null);

	constructor(
		private http: HttpService,
		private liveDataService: LiveDataService,
		private userService: UserService,
		private scheduleService: ScheduleService
	) {
		this.userService.userData
			.asObservable()
			.pipe(filter((user) => user?.isStudent()))
			.subscribe({
				next: (user) => {
					this.passLimitStudent$.next(user);
				},
			});

		this.passLimitStudent$
			.asObservable()
			.pipe(
				switchMap((user) => {
					if (!user?.isStudent()) {
						return of(null);
					}

					return merge(
						this.liveDataService.watchActiveHallPasses(new Subject<HallPassFilter>()).pipe(distinctUntilChanged((a, b) => a.length === b.length)),
						this.watchPassLimits(),
						this.watchIndividualPassLimit(user.id)
					).pipe(
						concatMap(() =>
							forkJoin({
								studentPassLimit: this.getStudentPassLimit(user.id),
								remainingLimit: this.getRemainingLimits({ studentId: user.id }),
								passLimit: this.getPassLimit(), // Include passLimit in the forkJoin result
								termData: this.scheduleService.getTermData$.pipe(take(1)),
							})
						),
						map(({ studentPassLimit, remainingLimit, passLimit, termData }) => {
							return {
								max: studentPassLimit.passLimit,
								showPasses: !studentPassLimit.noLimitsSet && !studentPassLimit.isUnlimited && studentPassLimit.passLimit !== null,
								current: remainingLimit.remainingPasses,
								frequency: passLimit.pass_limit?.frequency,
								termData: termData,
							};
						})
					);
				})
			)
			.subscribe((info) => {
				this.passLimitInfo$.next(info);
			});
	}

	// ------- Pass Limit Behaviour Subject management -------
	setPassLimitUser(user: User | null) {
		if (user === null) {
			this.passLimitStudent$.next(null);
			return;
		}

		if (user.isStudent()) {
			this.passLimitStudent$.next(user);
		}
	}

	passLimitChanges$() {
		return this.passLimitInfo$.asObservable().pipe(filter<PassLimitInfo>(Boolean));
	}

	// ------- Web Socket Listeners -------
	/**
	 * Watches for create and update pass limit messages from the backend
	 * and fetches the latest pass limit from the server
	 */
	watchPassLimits() {
		return this.liveDataService.watchPassLimits().pipe(
			map((d) => d[0]),
			distinctUntilChanged((a, b) => {
				return a?.limitEnabled === b?.limitEnabled && a?.passLimit === b?.passLimit && a?.frequency === b?.frequency;
			})
		);
	}

	watchIndividualPassLimit(studentId: number | string) {
		return this.liveDataService.watchIndividualPassLimit(studentId).pipe(
			map((d) => d[0]),
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
		);
	}

	// ------- Endpoints -------

	getPassLimit(): Observable<{ pass_limit: HallPassLimit }> {
		return this.http.get<{ pass_limit: HallPassLimit }>(`${PASS_LIMIT_ENDPOINT}/`);
	}

	createPassLimit(pl: HallPassLimit) {
		return this.http.post(`${PASS_LIMIT_ENDPOINT}/create`, pl, undefined, false).pipe(tap(() => this.individualLimitUpdate$.next()));
	}

	getRemainingLimits({ studentId }: { studentId: number | string }): Observable<{ remainingPasses: number }> {
		return this.http.get<{ remainingPasses: number }>(`${PASS_LIMIT_ENDPOINT}/remaining?student_id=${studentId}`);
	}

	updatePassLimits(pl: HallPassLimit) {
		return this.http.put(`${PASS_LIMIT_ENDPOINT}/update`, pl).pipe(tap(() => this.individualLimitUpdate$.next()));
	}

	getIndividualLimits(): Observable<IndividualPassLimit[]> {
		return this.http.get(`${PASS_LIMIT_ENDPOINT}/individual_overrides`);
	}

	createIndividualLimits(limit: IndividualPassLimitCollection) {
		return this.http.post(`${PASS_LIMIT_ENDPOINT}/create_override`, limit, undefined, false).pipe(tap(() => this.individualLimitUpdate$.next()));
	}

	getStudentPassLimit(studentId: string | number): Observable<StudentPassLimit> {
		return this.http.get(`${PASS_LIMIT_ENDPOINT}/student_limit?student_id=${studentId}`);
	}

	updateIndividualLimit(override: IndividualPassLimitCollection): Observable<null> {
		return this.http.put<null>(`${PASS_LIMIT_ENDPOINT}/update_override`, override, undefined).pipe(tap(() => this.individualLimitUpdate$.next()));
	}

	removeIndividualLimit(studentId: string | number) {
		return this.http.put(`${PASS_LIMIT_ENDPOINT}/remove_override?student_id=${studentId}`, {}).pipe(tap(() => this.individualLimitUpdate$.next()));
	}
}
