import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HttpService } from '../services/http-service';

@Injectable({
	providedIn: 'root',
})
export class IdCardStandaloneGuard implements CanActivate {
	constructor(private http: HttpService, private router: Router) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.http.currentSchool$.pipe(
			filter((school) => school !== null),
			map((school) => {
				if (school.hall_pass_access === 'no_access') {
					if (state.url.includes('id-card')) {
						return true;
					}
					return this.router.parseUrl('id-card');
				} else {
					if (state.url.includes('id-card')) {
						return this.router.parseUrl('/home');
					}

					return true;
				}
			})
		);
	}
}
