import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NavButtonComponent } from 'app/nav-button/nav-button.component';
import { MatchesRoutePipe } from 'app/pipes/matches-route.pipe';
import { SidenavEntitledTabComponent } from 'app/shared/sidenav-entitled-tab.component';
import { SidenavExpandingTabComponent } from 'app/shared/sidenav-expanding-tab.component';
import { SidenavRouteTabComponent } from 'app/shared/sidenav-route-tab.component';
import { ChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { AdditionalTermsComponent } from '../additional-terms/additional-terms.component';
import { AdminPassLimitDialogComponent } from '../admin-pass-limits-dialog/admin-pass-limits-dialog.component';
import { AccountGroupsComponent } from '../admin/accounts/account-groups/account-groups.component';
import { ProfileComponent } from '../admin/accounts/account-groups/profile/profile.component';
import { CreateGroupComponent } from '../admin/accounts/encounter-prevention-dialog/create-group/create-group.component';
import { EncounterGroupDescriptionComponent } from '../admin/accounts/encounter-prevention-dialog/encounter-group-description/encounter-group-description.component';
import { EncounterGroupComponent } from '../admin/accounts/encounter-prevention-dialog/encounter-group/encounter-group.component';
import { EncounterOptionsComponent } from '../admin/accounts/encounter-prevention-dialog/encounter-options/encounter-options.component';
import { EncounterOverridersAddRemoveComponent } from '../admin/accounts/encounter-prevention-dialog/encounter-overriders-add-remove/encounter-overriders-add-remove.component';
import { EncounterPreventionDialogComponent } from '../admin/accounts/encounter-prevention-dialog/encounter-prevention-dialog.component';
import { ReportDescriptionComponent } from '../admin/accounts/encounter-prevention-dialog/report-description/report-description.component';
import { GradeLevelsComponent } from '../admin/accounts/grade-levels/grade-levels.component';
import { IdNumbersComponent } from '../admin/accounts/id-numbers/id-numbers.component';
import { ClassLinkComponent } from '../admin/accounts/integrations-dialog/class-link/class-link.component';
import { PrivacyCardComponent } from '../admin/accounts/privacy-card/privacy-card.component';
import { ProfilePictureComponent } from '../admin/accounts/profile-picture/profile-picture.component';
import { SelectRoleComponent } from '../admin/accounts/select-role/select-role.component';
import { BackButtonComponent } from '../admin/back-button/back-button.component';
import { CalendarComponent } from '../admin/calendar/calendar.component';
import { EmergencyBannerComponent } from '../admin/emergency/emergency-banner/emergency-banner.component';
import { DateTimeFilterComponent } from '../admin/explore/date-time-filter/date-time-filter.component';
import { FilterButtonComponent } from '../admin/explore/filter-button/filter-button.component';
import { ReportInfoDialogComponent } from '../admin/explore/report-info-dialog/report-info-dialog.component';
import { StatusChipComponent } from '../admin/explore/status-chip/status-chip.component';
import { GettingStartedProgressService } from '../admin/getting-started-progress.service';
import { IdCardGradeLevelsComponent } from '../admin/id-cards/id-card-grade-levels/id-card-grade-levels.component';
import { IdCardIdNumbersComponent } from '../admin/id-cards/id-card-id-numbers/id-card-id-numbers.component';
import { InviteFamiliesDialogComponent } from '../admin/invite-families-dialog/invite-families-dialog.component';
import { LinkGeneratedDialogComponent } from '../admin/link-generated-dialog/link-generated-dialog.component';
import { NoFlyTimesComponent } from '../admin/no-fly-times/no-fly-times.component';
import { EditAvatarComponent } from '../admin/profile-card-dialog/edit-avatar/edit-avatar.component';
import { StatusPopupComponent } from '../admin/profile-card-dialog/status-popup/status-popup.component';
import { RestrictionDummyComponent } from '../admin/restriction-dummy/restriction-dummy.component';
import { RoundInputComponent } from '../admin/round-input/round-input.component';
import { AddEditHolidaysComponent } from '../admin/schedules/holidays/add-edit-holidays/add-edit-holidays.component';
import { ToggleInputComponent } from '../admin/toggle-input/toggle-input.component';
import { YearInReviewComponent } from '../admin/year-in-review/year-in-review.component';
import { AppInputComponent } from '../app-input/app-input.component';
import { AppTextareaComponent } from '../app-textarea/app-textarea.component';
import { BackpackModule } from '../backpack/backpack.module';
import { BottomNavBarComponent } from '../bottom-nav-bar/bottom-nav-bar.component';
import { AdminCalendarToggleComponent } from '../calendar-components/admin-calendar-toggle/admin-calendar-toggle.component';
import { ToggleOptionsComponent } from '../calendar-components/admin-calendar-toggle/toggle-options/toggle-options.component';
import { CalendarPickerComponent } from '../calendar-components/calendar-picker/calendar-picker.component';
import { TimePickerComponent } from '../calendar-components/time-picker/time-picker.component';
import { CardButtonComponent } from '../card-button/card-button.component';
import { AboutClassComponent } from '../class-details/about-class/about-class.component';
import { ClassDetailsComponent } from '../class-details/class-details.component';
import { ClassSyncInfoComponent, DaysAgoPipe } from '../class-details/class-sync-info/class-sync-info.component';
import { ClassStudentListItemComponent } from '../class-student-list/class-student-list-item/class-student-list-item.component';
import { ClassStudentListComponent } from '../class-student-list/class-student-list.component';
import { ClasslinkNoticeComponent } from '../class-student-list/classlink-notice/classlink-notice.component';
import { CleverNotice } from '../class-student-list/clever-notice/clever-notice.component';
import { GoogleNoticeComponent } from '../class-student-list/google-notice/google-notice.component';
import { StudentClassSearchComponent } from '../class-student-list/student-class-search/student-class-search.component';
import { ClassSyncInfoTooltipComponent } from '../class-sync-info-tooltip/class-sync-info-tooltip.component';
import { CollapsibleCardComponent, CollapsibleCardContent, CollapsibleCardHeader } from '../collapsible-card/collapsible-card.component';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { CoreModule } from '../core/core.module';
import { DragAndDropDirective } from '../core/directives/drag-and-drop.directive';
import { NavbarElementSenderDirective } from '../core/directives/navbar-element-sender.directive';
import { CreateHallpassFormsComponent } from '../create-hallpass-forms/create-hallpass-forms.component';
import { DateTimeContainerComponent } from '../create-hallpass-forms/main-hallpass--form/date-time-container/date-time-container.component';
import { DateTimeComponent } from '../create-hallpass-forms/main-hallpass--form/date-time-container/date-time/date-time.component';
import { FormFactorContainerComponent } from '../create-hallpass-forms/main-hallpass--form/form-factor-container/form-factor-container.component';
import { FromToWhereComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/from-to-where/from-to-where.component';
import { FromWhereComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/from-where/from-where.component';
import { LocationsGroupContainerComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { RestrictedMessageComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/restricted-message/restricted-message.component';
import { RestrictedTargetComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/restricted-target/restricted-target.component';
import { SelectCategoryRoomComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/select-category-room/select-category-room.component';
import { StudentFooterComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/student-footer/student-footer.component';
import { ToWhereComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/to-where/to-where.component';
import { MainHallPassFormComponent } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { GroupsContainerV2Component } from '../create-hallpass-forms/main-hallpass--form/student-groups/groups-container-v2/groups-container-v2.component';
import { GroupsSettingsComponent } from '../create-hallpass-forms/main-hallpass--form/student-groups/groups-settings/groups-settings.component';
import { SelectionComponent } from '../create-hallpass-forms/main-hallpass--form/student-groups/selection/selection.component';
import { CurrentlyInComponentV2 } from '../currently-in/currently-in-v2.component';
import { CurrentlyInComponent } from '../currently-in/currently-in.component';
import { DisplayOptionsComponent } from '../currently-in/display-options/display-options.component';
import { DisplayCardComponent } from '../display-card/display-card.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { IconOnlyButtonComponent } from '../ds-buttons/icon-only-button/icon-only-button.component';
import { TwinkieButtonComponent } from '../ds-buttons/twinkie-button/twinkie-button.component';
import { UserProfileButtonComponent } from '../ds-buttons/user-profile-button/user-profile-button.component';
import { WhiteButtonComponent } from '../ds-buttons/white-button/white-button.component';
import { WhiteRoundButtonComponent } from '../ds-buttons/white-round-button/white-round-button.component';
import { DurationPickerComponent } from '../duration-picker/duration-picker.component';
import { DynamicDialogModalComponent } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { FavoriteFormComponent } from '../favorite-form/favorite-form.component';
import { GlassButtonComponent } from '../glass-button/glass-button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { IosCalendarWheelComponent } from '../ios-calendar/ios-calendar-wheel/ios-calendar-wheel.component';
import { IosCalendarComponent } from '../ios-calendar/ios-calendar.component';
import { LocationCellComponent } from '../location-cell/location-cell.component';
import { LocationTableV2Component } from '../location-table-v2/location-table-v2.component';
import { LocationTableComponent } from '../location-table/location-table.component';
import { ChangeDisplaynameComponent } from '../my-profile-dialog/change-displayname/change-displayname.component';
import { ChangePasswordComponent } from '../my-profile-dialog/change-password/change-password.component';
import { MyProfileDialogComponent } from '../my-profile-dialog/my-profile-dialog.component';
import { ProfileInfoComponent } from '../my-profile-dialog/profile-info/profile-info.component';
import { NotificationFormInfoComponent } from '../notification-form/notification-form-info/notification-form-info.component';
import { NotificationFormComponent } from '../notification-form/notification-form.component';
import { NotificationOptionComponent } from '../notification-form/notification-option/notification-option.component';
import { NotificationSelectStudentsDialogComponent } from '../notification-form/notification-select-students-dialog/notification-select-students-dialog.component';
import { NotificationSelectStudentsComponent } from '../notification-form/notification-select-students/notification-select-students.component';
import { NuxUniversalComponent } from '../nux-components/nux-universal/nux-universal.component';
import {
	FilterEndedPassesPipe,
	OverrideEncounterPreventionComponent,
} from '../override-encounter-prevention/override-encounter-prevention.component';
import { PagerButtonComponent } from '../pager/pager-button/pager-button.component';
import { PagerComponent } from '../pager/pager.component';
import { PassCardComponent } from '../pass-card/pass-card.component';
import { WaitInLineCardComponent } from '../pass-cards/wait-in-line-card/wait-in-line-card.component';
import { PassCellComponent } from '../pass-cell/pass-cell.component';
import { PassCollectionComponent } from '../pass-collection/pass-collection.component';
import { CircleCountdown } from '../pass-countdown/circle-countdown.component';
import { PassLimitBulkEditComponent } from '../pass-limit-bulk-edit/pass-limit-bulk-edit.component';
import { PassLimitFeedbackComponent } from '../pass-limit-feedback/pass-limit-feedback.component';
import { PassLimitInfoComponent } from '../pass-limit-info/pass-limit-info.component';
import { PassLimitInputComponent } from '../pass-limit-input/pass-limit-input.component';
import { PassLimitStudentInfoComponent } from '../pass-limit-student-info/pass-limit-student-info.component';
import { CreatePassButtonComponent } from '../passes/create-pass-button/create-pass-button.component';
import { PassModalHeaderComponent } from '../passes/ui-components/pass-modal-header/pass-modal-header.component';
import { PassTileLargeComponent } from '../passes/ui-components/pass-tile-large/pass-tile-large.component';
import { PassTileMediumV2Component } from '../passes/ui-components/pass-tile-medium-v2/pass-tile-medium-v2.component';
import { PassTileMediumComponent } from '../passes/ui-components/pass-tile-medium/pass-tile-medium.component';
import { PinnableComponent } from '../pinnable/pinnable.component';
import { AsFormArrayPipe } from '../pipes/as-form-array.pipe';
import { ButtonVisibilityPipe } from '../pipes/button-visibility.pipe';
import { ComingThisPeriodPipe } from '../pipes/coming-this-period.pipe';
import { CountdownPipe } from '../pipes/countdown.pipe';
import { EventHasOverlappingEventsPipe } from '../pipes/event-has-overlapping-events.pipe';
import { MilitaryTimeStringPipe } from '../pipes/military-time-string.pipe';
import { MomentFormatPipePipe } from '../pipes/moment-format.pipe';
import { RequestDatePipe } from '../pipes/request-date.pipe';
import { SelectedBellSchedulePipe } from '../pipes/selected-bell-schedule.pipe';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { UserInArrayPipe } from '../pipes/user-in-array.pipe';
import { WithLoadingPipe } from '../pipes/with-loading.pipe';
import { ReactComponentsModule } from '../react-components/react-components.module';
import { RenameComponent } from '../rename-class/rename.component';
import { ReportSuccessToastComponent } from '../report-success-toast/report-success-toast.component';
import { ConfirmDeleteKioskModeComponent } from '../request-card/confirm-delete-kiosk-mode/confirm-delete-kiosk-mode.component';
import { RequestCardComponent } from '../request-card/request-card.component';
import { RestrictionPickerComponent } from '../restriction-picker/restriction-picker.component';
import { RoomCodeComponent } from '../room-code/room-code.component';
import { RoomIconComponent } from '../room-icon/room-icon.component';
import { RoundButtonComponent } from '../round-button/round-button.component';
import { SafariScrollDirective } from '../safari-scroll.directive';
import { ScheduleProcessingComponent } from '../schedule-processing/schedule-processing.component';
import { OverrideBodyComponent } from '../services/locations.service';
import { TimeZoneService } from '../services/time-zone.service';
import { SettingsDescriptionPopupComponent } from '../settings-description-popup/settings-description-popup.component';
import { SettingsNavBarComponent } from '../settings-nav-bar/settings-nav-bar.component';
import { FeatureFlagPanelComponent } from '../settings/feature-flag-panel/feature-flag-panel.component';
import { SettingsFooterComponent } from '../settings/settings-footer/settings-footer.component';
import { SettingsComponent } from '../settings/settings.component';
import { ShortenOrDeleteTermsComponent } from '../shorten-or-delete-terms/shorten-or-delete-terms.component';
import { SmartpassLogoComponent } from '../smartpass-logo/smartpass-logo.component';
import { SmartpassSearchComponent } from '../smartpass-search/smartpass-search.component';
import { SortMenuComponent } from '../sort-menu/sort-menu.component';
import { SpAppearanceComponent } from '../sp-appearance/sp-appearance.component';
import { SpChipComponent } from '../sp-chip/sp-chip.component';
import { SpChipsComponent } from '../sp-chips/sp-chips.component';
import { SpInputComponent } from '../sp-input/sp-input.component';
import { SpLanguageComponent } from '../sp-language/sp-language.component';
import { SPSearchComponent } from '../sp-search/sp-search.component';
import { SquareButtonComponent } from '../square-button/square-button.component';
import { CountdownLabelComponent } from '../student-active-pass/countdown-label/countdown-label.component';
import { StudentActivePassComponent } from '../student-active-pass/student-active-pass.component';
import { ThinPassComponent } from '../student-active-pass/thin-pass.component';
import { StudentListItemComponent, StudentListItemRightContent } from '../student-list/student-list-item.component';
import { StudentPassInfoComponent } from '../student-list/student-pass-info.component';
import { StudentMetricsComponent } from '../student-metrics/student-metrics.component';
import { DateButtonComponent } from '../student-snapshot-page/date-button/date-button.component';
import { OverviewContainerComponent } from '../student-snapshot-page/student-passes-overwiew/overview-container.component';
import { StudentWaitInLinePassComponent } from '../student-wait-in-line-pass/student-wait-in-line-pass.component';
import { TabbedContentModalComponent } from '../tabbed-content-modal/tabbed-content-modal.component';
import { AttendeeStudentComponent } from '../teacher-activity/attendees/attendee-student.component';
import { StarlightSelect, StarlightSelectDropdown } from '../teacher-activity/attendees/starlight-select.component';
import { StudentInstanceSearchComponent } from '../teacher-activity/attendees/student-instance-search/student-instance-search.component';
import { TeacherPinEndPassComponent } from '../teacher-pin-end-pass/teacher-pin-end-pass.component';
import { TeacherPinStudentComponent } from '../teacher-pin-student/teacher-pin-student.component';
import { PinInputComponent } from '../teacher-pin/pin-input/pin-input.component';
import { TeacherPinComponent } from '../teacher-pin/teacher-pin.component';
import { TeacherSelectionComponent } from '../teacher-selection/teacher-selection.component';
import { TopBarCreatePassButtonComponent } from '../top-bar-create-pass/top-bar-create-pass.component';
import { TopBarSchedulePassComponent } from '../top-bar-schedule-pass/top-bar-schedule-pass.component';
import { NavTabsComponent } from '../top-nav-bar/nav-tabs.component';
import { TopNavBarComponent } from '../top-nav-bar/top-nav-bar.component';
import { TravelViewComponent } from '../travel-view/travel-view.component';
import { TraveltypePickerComponent } from '../traveltype-picker/traveltype-picker.component';
import { AddRemoveChipComponent } from './add-remove-chip/add-remove-chip.component';
import { ArchiveComponent } from './archive/archive.component';
import { ClassPermissionsFormComponent } from './class-permissions-form/class-permissions-form.component';
import { AndOthersPipe, ClassSetupCardComponent } from './class-setup-card/class-setup-card.component';
import { ClassPeriodPickerComponent } from './class-term-and-period-form/class-period-picker/class-period-picker.component';
import { ClassTermAndPeriodFormComponent } from './class-term-and-period-form/class-term-and-period-form.component';
import { ClassTermPickerComponent } from './class-term-and-period-form/class-term-picker/class-term-picker.component';
import { HoverDirective } from './directives/hover.directive';
import { EditRoomFormComponent } from './edit-room-form/edit-room-form.component';
import { EditTeacherFormComponent } from './edit-teacher-form/edit-teacher-form.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { PassCollectionV2Module } from './pass-collection-v2/pass-collection-v2.module';
import { PassCounterChipComponent } from './pass-counter-chip/pass-counter-chip.component';
import { SchoolTermComponent } from './school-term/school-term.component';
import { SelectComponent } from './select/select.component';
import { NoPeriodsComponent } from './set-schedule/no-periods/no-periods.component';
import { SetScheduleComponent } from './set-schedule/set-schedule.component';
import { BottomBannerComponent } from './shared-components/bottom-banner/bottom-banner.component';
import { CallDialogComponent } from './shared-components/call-dialog/call-dialog.component';
import { ConfirmationDialogComponent } from './shared-components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationComponent } from './shared-components/confirmation/confirmation.component';
import { CustomToolTipComponent } from './shared-components/custom-tool-tip/custom-tool-tip.component';
import { GroupsAndStudentSelectComponent } from './shared-components/groups-and-student-select/groups-and-student-select.component';
import { HelpCenterComponent } from './shared-components/help-center/help-center.component';
import { NoFlyTimeAdminNuxComponent } from './shared-components/no-fly-time-admin-nux/no-fly-time-admin-nux.component';
import { NoFlyTimeDialogComponent } from './shared-components/no-fly-time-dialog/no-fly-time-dialog.component';
import { NoFlyTimeTooltipComponent } from './shared-components/no-fly-time-tooltip/no-fly-time-tooltip.component';
import { NoPassMessageComponent } from './shared-components/no-pass-message/no-pass-message.component';
import { OriginLocationTooltipComponent } from './shared-components/origin-location-tooltip/origin-location-tooltip.component';
import { PassLimitTooltipComponent } from './shared-components/pass-limit-tooltip/pass-limit-tooltip.component';
import { RoomCheckinCodeDialogComponent } from './shared-components/room-checkin-code-dialog/room-checkin-code-dialog.component';
import { SchedulePassComponent } from './shared-components/schedule-pass/schedule-pass.component';
import { ScheduleTooltipComponent } from './shared-components/schedule-tooltip/schedule-tooltip.component';
import { SelectDaysComponent } from './shared-components/select-days/select-days.component';
import { SubnavTabsComponent } from './subnav-tabs/subnav-tabs.component';
import { TableV2Component } from './table-v2/table-v2.component';
import { TimeRangeInputModule } from './time-range-input/time-range-input.module';
import { WilHelpIconComponent } from './wil-help-icon/wil-help-icon.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: 'horizontal',
	slidesPerView: 'auto',
};

@NgModule({
	imports: [
		CommonModule,
		ScrollingModule,
		FormsModule,
		ReactiveFormsModule,
		DragulaModule.forRoot(),
		SwiperModule,
		ChartsModule,
		CoreModule,
		BackpackModule,
		PassCollectionV2Module,
		TimeRangeInputModule,
		ReactComponentsModule,
		RouterModule,
		MatDialogModule,
	],
	declarations: [
		CreateHallpassFormsComponent,
		LocationTableV2Component,
		RestrictedMessageComponent,
		RestrictedTargetComponent,
		StudentFooterComponent,
		SelectCategoryRoomComponent,
		FromWhereComponent,
		ToWhereComponent,
		FromToWhereComponent,
		FormFactorContainerComponent,
		LocationsGroupContainerComponent,
		DateTimeContainerComponent,
		DateTimeComponent,
		MainHallPassFormComponent,
		LocationCellComponent,
		PinInputComponent,
		NotificationFormInfoComponent,
		NotificationSelectStudentsComponent,
		NotificationSelectStudentsDialogComponent,
		NotificationOptionComponent,
		LocationTableComponent,
		NotificationFormComponent,
		TeacherPinComponent,
		FavoriteFormComponent,
		SmartpassLogoComponent,
		DisplayCardComponent,
		PinnableComponent,
		TraveltypePickerComponent,
		SPSearchComponent,
		PassCollectionComponent,
		PassCellComponent,
		RestrictionDummyComponent,
		BackButtonComponent,
		PassCardComponent,
		TravelViewComponent,
		DurationPickerComponent,
		CardButtonComponent,
		PagerComponent,
		PagerButtonComponent,
		RoundInputComponent,
		NoFlyTimesComponent,
		AppInputComponent,
		CalendarComponent,
		SafariScrollDirective,
		ToggleInputComponent,
		CalendarPickerComponent,
		TimePickerComponent,
		AdminCalendarToggleComponent,
		ToggleOptionsComponent,
		SpChipsComponent,
		GroupsAndStudentSelectComponent,
		SpChipComponent,
		IconButtonComponent,
		CreatePassButtonComponent,
		TopBarCreatePassButtonComponent,
		TopBarSchedulePassComponent,
		IosCalendarWheelComponent,
		IosCalendarComponent,
		NavbarElementSenderDirective,
		DragAndDropDirective,
		ConsentMenuComponent,
		SortMenuComponent,
		PrivacyCardComponent,
		ProfileComponent,
		AccountGroupsComponent,
		ReportSuccessToastComponent,
		RestrictionPickerComponent,
		SpAppearanceComponent,
		SpLanguageComponent,
		TeacherPinStudentComponent,
		TeacherPinEndPassComponent,
		RoomCodeComponent,
		MyProfileDialogComponent,
		ProfileInfoComponent,
		ChangePasswordComponent,
		ChangeDisplaynameComponent,
		CustomToolTipComponent,
		PassLimitTooltipComponent,
		NoFlyTimeTooltipComponent,
		NoFlyTimeDialogComponent,
		NoFlyTimeAdminNuxComponent,
		ScheduleTooltipComponent,
		OriginLocationTooltipComponent,
		PassTileMediumComponent,
		PassTileLargeComponent,
		StudentMetricsComponent,
		RequestCardComponent,
		WaitInLineCardComponent,
		DropdownComponent,
		SettingsDescriptionPopupComponent,
		AppTextareaComponent,
		SquareButtonComponent,
		RoundButtonComponent,
		SmartpassSearchComponent,
		EncounterGroupComponent,
		SelectRoleComponent,
		DateTimeFilterComponent,
		ReportInfoDialogComponent,
		StatusPopupComponent,
		EncounterPreventionDialogComponent,
		CreateGroupComponent,
		ReportDescriptionComponent,
		EncounterOptionsComponent,
		EncounterGroupDescriptionComponent,
		WhiteButtonComponent,
		WhiteRoundButtonComponent,
		IconOnlyButtonComponent,
		UserProfileButtonComponent,
		CurrentlyInComponent,
		CurrentlyInComponentV2,
		DisplayOptionsComponent,
		TwinkieButtonComponent,
		EditAvatarComponent,
		ProfilePictureComponent,
		LinkGeneratedDialogComponent,
		StatusChipComponent,
		ConfirmationDialogComponent,
		NuxUniversalComponent,
		AdminPassLimitDialogComponent,
		PassLimitInfoComponent,
		PassLimitInputComponent,
		PassLimitFeedbackComponent,
		PassLimitBulkEditComponent,
		PassLimitStudentInfoComponent,
		ConfirmationComponent,
		IdNumbersComponent,
		GradeLevelsComponent,
		IdCardGradeLevelsComponent,
		IdCardIdNumbersComponent,
		ConfirmDeleteKioskModeComponent,
		ClassLinkComponent,
		TeacherSelectionComponent,
		DateButtonComponent,
		OverviewContainerComponent,
		SettingsComponent,
		InviteFamiliesDialogComponent,
		HelpCenterComponent,
		CallDialogComponent,
		YearInReviewComponent,
		EmergencyBannerComponent,
		ButtonVisibilityPipe,
		TimeAgoPipe,
		MilitaryTimeStringPipe,
		ComingThisPeriodPipe,
		UserInArrayPipe,
		AsFormArrayPipe,
		UserInArrayPipe,
		RequestDatePipe,
		MomentFormatPipePipe,
		CountdownPipe,
		EventHasOverlappingEventsPipe,
		GroupsAndStudentSelectComponent,
		GroupsSettingsComponent,
		RoomCheckinCodeDialogComponent,
		StudentActivePassComponent,
		StudentWaitInLinePassComponent,
		GlassButtonComponent,
		ThinPassComponent,
		CircleCountdown,
		CountdownLabelComponent,
		RoomIconComponent,
		SchedulePassComponent,
		SelectDaysComponent,
		SelectionComponent,
		StarlightSelect,
		StarlightSelectDropdown,
		AttendeeStudentComponent,
		AttendeeStudentComponent,
		GroupsContainerV2Component,
		StudentInstanceSearchComponent,
		BottomBannerComponent,
		PassModalHeaderComponent,
		CollapsibleCardComponent,
		CollapsibleCardHeader,
		CollapsibleCardContent,
		StudentListItemComponent,
		StudentListItemRightContent,
		StudentPassInfoComponent,
		NoPassMessageComponent,
		DynamicDialogModalComponent,
		FeatureFlagPanelComponent,
		SettingsFooterComponent,
		WithLoadingPipe,
		SelectedBellSchedulePipe,
		ClassDetailsComponent,
		ClassStudentListComponent,
		ClassStudentListItemComponent,
		SpInputComponent,
		StudentClassSearchComponent,
		CleverNotice,
		ClasslinkNoticeComponent,
		GoogleNoticeComponent,
		HoverDirective,
		AboutClassComponent,
		ClassSyncInfoComponent,
		RenameComponent,
		ClassPeriodPickerComponent,
		EditTeacherFormComponent,
		EditRoomFormComponent,
		ClassPermissionsFormComponent,
		SetScheduleComponent,
		ClassTermPickerComponent,
		AddRemoveChipComponent,
		ClassSetupCardComponent,
		ClassTermAndPeriodFormComponent,
		DaysAgoPipe,
		AndOthersPipe,
		ClassSyncInfoTooltipComponent,
		SchoolTermComponent,
		ScheduleProcessingComponent,
		PassTileMediumV2Component,
		EncounterOverridersAddRemoveComponent,
		OverrideEncounterPreventionComponent,
		AdditionalTermsComponent,
		FilterEndedPassesPipe,
		TabbedContentModalComponent,
		AddEditHolidaysComponent,
		NoPeriodsComponent,
		ShortenOrDeleteTermsComponent,
		PassCounterChipComponent,
		OverrideBodyComponent,
		TopNavBarComponent,
		BottomNavBarComponent,
		NavButtonComponent,
		NavTabsComponent,
		TableV2Component,
		EmptyStateComponent,
		FilterButtonComponent,
		MatchesRoutePipe,
		SettingsNavBarComponent,
		SidenavExpandingTabComponent,
		SidenavRouteTabComponent,
		SidenavEntitledTabComponent,
		SelectComponent,
		ArchiveComponent,
		WilHelpIconComponent,
		SubnavTabsComponent,
	],
	exports: [
		LocationTableV2Component,
		RestrictedMessageComponent,
		RestrictedTargetComponent,
		SelectCategoryRoomComponent,
		FromWhereComponent,
		ToWhereComponent,
		FromToWhereComponent,
		FormFactorContainerComponent,
		LocationsGroupContainerComponent,
		DateTimeContainerComponent,
		DateTimeComponent,
		MainHallPassFormComponent,
		LocationCellComponent,
		PinInputComponent,
		NotificationFormInfoComponent,
		NotificationSelectStudentsComponent,
		NotificationSelectStudentsDialogComponent,
		NotificationOptionComponent,
		NotificationFormComponent,
		TeacherPinComponent,
		FavoriteFormComponent,
		ReactiveFormsModule,
		ScrollingModule,
		DragulaModule,
		PassCollectionV2Module,
		TimeRangeInputModule,
		GroupsAndStudentSelectComponent,
		SmartpassLogoComponent,
		DisplayCardComponent,
		PinnableComponent,
		TraveltypePickerComponent,
		SPSearchComponent,
		PassCollectionComponent,
		PassCellComponent,
		RestrictionDummyComponent,
		BackButtonComponent,
		TravelViewComponent,
		DurationPickerComponent,
		CardButtonComponent,
		PagerComponent,
		PagerButtonComponent,
		RoundInputComponent,
		NoFlyTimesComponent,
		AppInputComponent,
		PassCardComponent,
		CalendarComponent,
		SafariScrollDirective,
		ToggleInputComponent,
		CalendarPickerComponent,
		TimePickerComponent,
		AdminCalendarToggleComponent,
		ToggleOptionsComponent,
		SpChipsComponent,
		SpChipComponent,
		IconButtonComponent,
		CreatePassButtonComponent,
		TopBarCreatePassButtonComponent,
		TopBarSchedulePassComponent,
		IosCalendarWheelComponent,
		IosCalendarComponent,
		ChartsModule,
		SmartpassLogoComponent,
		CoreModule,
		BackpackModule,
		PrivacyCardComponent,
		ProfileComponent,
		AccountGroupsComponent,
		ReportSuccessToastComponent,
		RestrictionPickerComponent,
		ProfileInfoComponent,
		ChangePasswordComponent,
		ChangeDisplaynameComponent,
		TeacherPinStudentComponent,
		TeacherPinEndPassComponent,
		RoomCodeComponent,
		PassLimitTooltipComponent,
		NoFlyTimeTooltipComponent,
		NoFlyTimeDialogComponent,
		NoFlyTimeAdminNuxComponent,
		ScheduleTooltipComponent,
		OriginLocationTooltipComponent,
		PassTileMediumComponent,
		PassTileLargeComponent,
		StudentMetricsComponent,
		RequestCardComponent,
		WaitInLineCardComponent,
		DropdownComponent,
		AppTextareaComponent,
		SquareButtonComponent,
		RoundButtonComponent,
		CustomToolTipComponent,
		SmartpassSearchComponent,
		EncounterGroupComponent,
		SelectRoleComponent,
		DateTimeFilterComponent,
		ReportInfoDialogComponent,
		StatusPopupComponent,
		EncounterPreventionDialogComponent,
		CreateGroupComponent,
		ReportDescriptionComponent,
		EncounterOptionsComponent,
		EncounterGroupDescriptionComponent,
		WhiteButtonComponent,
		WhiteRoundButtonComponent,
		IconOnlyButtonComponent,
		UserProfileButtonComponent,
		CurrentlyInComponent,
		CurrentlyInComponentV2,
		DisplayOptionsComponent,
		TwinkieButtonComponent,
		EditAvatarComponent,
		ProfilePictureComponent,
		LinkGeneratedDialogComponent,
		StatusChipComponent,
		NuxUniversalComponent,
		AdminPassLimitDialogComponent,
		PassLimitInfoComponent,
		PassLimitInputComponent,
		PassLimitFeedbackComponent,
		PassLimitBulkEditComponent,
		PassLimitStudentInfoComponent,
		IdNumbersComponent,
		GradeLevelsComponent,
		IdCardGradeLevelsComponent,
		IdCardIdNumbersComponent,
		ConfirmDeleteKioskModeComponent,
		ClassLinkComponent,
		TeacherSelectionComponent,
		DateButtonComponent,
		OverviewContainerComponent,
		SettingsComponent,
		InviteFamiliesDialogComponent,
		HelpCenterComponent,
		CallDialogComponent,
		YearInReviewComponent,
		EmergencyBannerComponent,
		ButtonVisibilityPipe,
		TimeAgoPipe,
		MilitaryTimeStringPipe,
		ComingThisPeriodPipe,
		AsFormArrayPipe,
		UserInArrayPipe,
		RequestDatePipe,
		MomentFormatPipePipe,
		CountdownPipe,
		EventHasOverlappingEventsPipe,
		GroupsAndStudentSelectComponent,
		DragAndDropDirective,
		GroupsSettingsComponent,
		RoomCheckinCodeDialogComponent,
		StudentActivePassComponent,
		StudentWaitInLinePassComponent,
		GlassButtonComponent,
		ThinPassComponent,
		CircleCountdown,
		CountdownLabelComponent,
		RoomIconComponent,
		SchedulePassComponent,
		SelectDaysComponent,
		SelectRoleComponent,
		StarlightSelect,
		StarlightSelectDropdown,
		AttendeeStudentComponent,
		AttendeeStudentComponent,
		GroupsContainerV2Component,
		PassModalHeaderComponent,
		StudentInstanceSearchComponent,
		BottomBannerComponent,
		CollapsibleCardComponent,
		CollapsibleCardHeader,
		CollapsibleCardContent,
		StudentListItemComponent,
		StudentListItemRightContent,
		StudentPassInfoComponent,
		NoPassMessageComponent,
		DynamicDialogModalComponent,
		FeatureFlagPanelComponent,
		SettingsFooterComponent,
		WithLoadingPipe,
		SelectedBellSchedulePipe,
		ClassStudentListComponent,
		ClassStudentListItemComponent,
		SpInputComponent,
		StudentClassSearchComponent,
		CleverNotice,
		ClasslinkNoticeComponent,
		GoogleNoticeComponent,
		HoverDirective,
		AboutClassComponent,
		ClassSyncInfoComponent,
		RenameComponent,
		ClassPeriodPickerComponent,
		EditRoomFormComponent,
		EditTeacherFormComponent,
		ClassPermissionsFormComponent,
		SetScheduleComponent,
		ClassTermPickerComponent,
		ClassSetupCardComponent,
		ClassTermAndPeriodFormComponent,
		DaysAgoPipe,
		AndOthersPipe,
		ClassSyncInfoTooltipComponent,
		SchoolTermComponent,
		ScheduleProcessingComponent,
		PassTileMediumV2Component,
		EncounterOverridersAddRemoveComponent,
		OverrideEncounterPreventionComponent,
		AdditionalTermsComponent,
		TabbedContentModalComponent,
		AddEditHolidaysComponent,
		ShortenOrDeleteTermsComponent,
		PassCounterChipComponent,
		OverrideBodyComponent,
		ClassDetailsComponent,
		TopNavBarComponent,
		BottomNavBarComponent,
		NavButtonComponent,
		NavTabsComponent,
		TableV2Component,
		EmptyStateComponent,
		FilterButtonComponent,
		SettingsNavBarComponent,
		MatchesRoutePipe,
		SidenavExpandingTabComponent,
		SidenavRouteTabComponent,
		SidenavEntitledTabComponent,
		LocationTableComponent,
		SelectComponent,
		CreateHallpassFormsComponent,
		ArchiveComponent,
		WilHelpIconComponent,
		SubnavTabsComponent,
	],
	providers: [
		{
			provide: SWIPER_CONFIG,
			useValue: DEFAULT_SWIPER_CONFIG,
		},
		{ provide: 'TimeZoneService', useClass: TimeZoneService },
		GettingStartedProgressService,
		ButtonVisibilityPipe,
		TimeAgoPipe,
		MilitaryTimeStringPipe,
		ComingThisPeriodPipe,
		AsFormArrayPipe,
		UserInArrayPipe,
		RequestDatePipe,
		MomentFormatPipePipe,
		CountdownPipe,
		EventHasOverlappingEventsPipe,
		{ provide: MatDialogRef, useValue: {} },
	],
})
export class SharedModule {}
