import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconAdminComponent } from './icons/icon-admin.component';
import { IconAppearanceComponent } from './icons/icon-appearance.component';
import { IconArrowLeft02Component } from './icons/icon-arrow-left-02.component';
import { IconBalloonsComponent } from './icons/icon-balloons.component';
import { IconCalendarComponent } from './icons/icon-calendar.component';
import { IconChangeUserComponent } from './icons/icon-change-user.component';
import { IconClassComponent } from './icons/icon-class.component';
import { IconClock03Component } from './icons/icon-clock-03.component';
import { IconContactTraceComponent } from './icons/icon-contact-trace.component';
import { IconDashboardComponent } from './icons/icon-dashboard.component';
import { IconDataComponent } from './icons/icon-data.component';
import { IconDetectedEncountersComponent } from './icons/icon-detected-encounters.component';
import { IconEditComponent } from './icons/icon-edit.component';
import { IconEmergencyComponent } from './icons/icon-emergency.component';
import { IconEmptyBoxComponent } from './icons/icon-empty-box.component';
import { IconError01Component } from './icons/icon-error-01.component';
import { IconExploreComponent } from './icons/icon-explore.component';
import { IconFlexComponent } from './icons/icon-flex.component';
import { IconGiftComponent } from './icons/icon-gift.component';
import { IconGlobeComponent } from './icons/icon-globe.component';
import { IconGroupComponent } from './icons/icon-group.component';
import { IconHallMonitorComponent } from './icons/icon-hall-monitor.component';
import { IconHelpComponent } from './icons/icon-help.component';
import { IconIdCard02Component } from './icons/icon-id-card-02.component';
import { IconLaunchComponent } from './icons/icon-launch.component';
import { IconPassesComponent } from './icons/icon-passes.component';
import { IconPasswordComponent } from './icons/icon-password.component';
import { IconRoomComponent } from './icons/icon-room.component';
import { IconSchoolComponent } from './icons/icon-school.component';
import { IconSettingsTeacherComponent } from './icons/icon-settings-teacher.component';
import { IconShirtComponent } from './icons/icon-shirt.component';
import { IconStarComponent } from './icons/icon-star.component';
import { IconUserComponent } from './icons/icon-user.component';
import { IconVolumeComponent } from './icons/icon-volume.component';

/**
 * This component provides a simple interface for rendering any Backpack icon.
 * Icons are automatically centered in a box of the specified size.
 *
 * Usage: `<bp-icon icon="dashboard" size="24"></bp-icon>`
 *
 * To add a new icon: (Only add icons that exist in Backpack in Figma!)
 *  1. Create a new component in the `icons` directory
 *  2. Edit the SVG template to remove the `width` and `height` attributes, and set `fill` and `stroke` to `currentcolor`
 *  2. Add the component to the module's `declarations` array, and add it to the `icons` record below.
 */
@Component({
	selector: 'bp-icon',
	template: ` <ng-container *ngComponentOutlet="iconComponent"></ng-container>`,
	styles: [
		`
			:host {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 0 0 auto;

				& > * {
					display: contents;
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackpackIconComponent {
	@HostBinding('style.height.px')
	@HostBinding('style.width.px')
	@Input()
	size = '20';

	@Input() icon = 'dashboard';

	get iconComponent() {
		return icons[this.icon];
	}
}

const icons: Record<string, ComponentType<void>> = {
	admin: IconAdminComponent,
	appearance: IconAppearanceComponent,
	'arrow-left-02': IconArrowLeft02Component,
	balloons: IconBalloonsComponent,
	calendar: IconCalendarComponent,
	class: IconClassComponent,
	'clock-03': IconClock03Component,
	'change-user': IconChangeUserComponent,
	'contact-trace': IconContactTraceComponent,
	dashboard: IconDashboardComponent,
	data: IconDataComponent,
	'detected-encounters': IconDetectedEncountersComponent,
	edit: IconEditComponent,
	emergency: IconEmergencyComponent,
	'empty-box': IconEmptyBoxComponent,
	'error-01': IconError01Component,
	explore: IconExploreComponent,
	flex: IconFlexComponent,
	gift: IconGiftComponent,
	globe: IconGlobeComponent,
	group: IconGroupComponent,
	'hall-monitor': IconHallMonitorComponent,
	help: IconHelpComponent,
	'id-card-02': IconIdCard02Component,
	passes: IconPassesComponent,
	password: IconPasswordComponent,
	room: IconRoomComponent,
	school: IconSchoolComponent,
	'settings-teacher': IconSettingsTeacherComponent,
	shirt: IconShirtComponent,
	star: IconStarComponent,
	user: IconUserComponent,
	volume: IconVolumeComponent,
	launch: IconLaunchComponent,
};
